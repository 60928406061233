import React, { useState, useEffect } from 'react'
import { getPendingMerchantAuthObjNetwork, getApprovedMerchantAuthNetwork, getRejectedMerchantAuthNetwork, changeMerchantStatusNetwork } from './network'
import { Select, PageHeader, Space, Table, Button, Tag, Form, Divider } from 'antd'
import { BulkDealerManager } from './bulk'

const { Option } = Select

export default function UserManager() {
    const [statusType, setStatusType] = useState<String>('pending')
    const [pendingUser, setPendingUser] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getPendingMerchants()
    }, [])

    const handleChange = (value: string) => {
        setStatusType(value)
        if (value === 'rejected') getRejectedUsers()
        if (value === 'approved') getApprovedUsers()
        if (value === 'pending') getPendingMerchants()
    }

    const getPendingMerchants = async () => {
        setLoading(true)
        let pendingUsers = await getPendingMerchantAuthObjNetwork()
        if (pendingUsers.data) {
            setPendingUser(pendingUsers.data.data)
            setLoading(false)
        }
    }

    const getApprovedUsers = async () => {
        setLoading(true)
        let pendingUsers = await getApprovedMerchantAuthNetwork()
        if (pendingUsers.data) {
            setPendingUser(pendingUsers.data)
            setLoading(false)
        }
    }

    const getRejectedUsers = async () => {
        setLoading(true)
        let pendingUsers = await getRejectedMerchantAuthNetwork()
        if (pendingUsers.data) {
            setPendingUser(pendingUsers.data)
            setLoading(false)
        }
    }

    const changeMerchantStatus = async (record: any, status: string) => {
        setLoading(true)
        const resp = await changeMerchantStatusNetwork({ id: record.id, status })

        if (resp.status) {
            getPendingMerchants()
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (record: any) => {
                return record.dealerName
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'msisdn',
            key: 'msisdn',
        },
        {
            title: 'Province',
            key: 'province',
            render: (record: any) => {
                return record.province
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (status: any) => {
                let color = 'green'
                if (status === 'pending') {
                    color = 'geekblue'
                }
                if (status === 'rejected') {
                    color = 'volcano'
                }

                return (
                    <Tag color={color} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                )
            },
        },
    ]

    const actions: any = [
        {
            title: 'Action',
            key: 'action',
            render: (record: any) => (
                <Space size="middle">
                    <Button onClick={() => changeMerchantStatus(record, 'approved')} style={{ color: 'green', borderColor: 'green' }}>
                        Approve
                    </Button>
                    <Button onClick={() => changeMerchantStatus(record, 'rejected')} danger ghost>
                        Reject
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <div>
            <PageHeader title="Dealer Manager" />
            <Form.Item label="Status" style={{ marginLeft: 24 }}>
                <Select defaultValue="pending" style={{ width: 220 }} onChange={handleChange}>
                    <Option value="pending">Pending</Option>
                    <Option value="rejected">Rejected</Option>
                    <Option value="approved">Approved</Option>
                </Select>
            </Form.Item>
            <Table loading={loading} columns={statusType === 'pending' ? columns.concat(actions) : columns} dataSource={pendingUser} />

            <PageHeader title="Add Dealers" />

            <BulkDealerManager />
        </div>
    )
}
