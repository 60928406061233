import Rio from '@retter/sdk'

// c.retter.io
// export const rio = Retter.getInstance({
// 	projectId: 'vqk2n6tud',
// 	region: RetterRegion.euWest1
// })

// jotuntr.c.retter.io
// TEST - 51o57i44v
// DEV - jl5kc9c29
// PROD - jlcft9ss5

export const rio = Rio.getInstance({
    projectId: process.env.REACT_APP_PROJECT_ID,
    url: process.env.REACT_APP_API_URL,
})

export let ContentCloudObj
export let ProductManagerCloudObj
export let ManagerCloudObj
export let BannerCloudObj
export let UserCloudObj
export let MerchantAuthObj
export let OrderCloudObj
export let PlacesCloudObj

export const GetContentCloudObject = async () => {
    ContentCloudObj = await rio.getCloudObject({
        classId: 'Content',
    })
}

export const GetProductCloudObject = async () => {
    ProductManagerCloudObj = await rio.getCloudObject({
        classId: 'ProductManager',
    })
}

export const GetManagerCloudObject = async () => {
    ManagerCloudObj = await rio.getCloudObject({
        classId: 'Manager',
    })
}

export const GetBannerCloudObject = async () => {
    BannerCloudObj = await rio.getCloudObject({
        classId: 'BannerManager',
        instanceId: 'default',
        useLocal: true,
    })
}

export const GetUserCloudObject = async (instance = null) => {
    UserCloudObj = await rio.getCloudObject({
        classId: 'User',
        instanceId: instance,
    })
}

export const GetMerchantAuthCloudObject = async (instance = null) => {
    MerchantAuthObj = await rio.getCloudObject({
        classId: 'MerchantAuth',
        instanceId: instance,
        useLocal: true,
    })
}

export const GetOrderCloudObject = async () => {
    OrderCloudObj = await rio.getCloudObject({
        classId: 'Order',
        instanceId: 'default',
        useLocal: true,
    })
}

export const GetPlacesCloudObject = async () => {
    PlacesCloudObj = await rio.getCloudObject({
        classId: 'Places',
        instanceId: 'default',
        useLocal: true,
    })
}
