import { ManagerCloudObj, MerchantAuthObj } from '../../utils/RBS'

export const getPendingUsersNetwork = async () => {

  const response: any = await ManagerCloudObj.call({ method: 'getPendingUsers' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getApprovedUsersNetwork = async () => {
  const response: any = await ManagerCloudObj.call({ method: 'getApprovedUsers' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getRejectedUsersNetwork = async () => {
  const response: any = await ManagerCloudObj.call({ method: 'getRejectedUsers' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}


// MERCHANT AUTH
export const getPendingMerchantAuthObjNetwork = async () => {
  const response: any = await MerchantAuthObj.call({ method: 'getMerchantPendingList' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const changeMerchantStatusNetwork = async (body: any) => {
  const response: any = await MerchantAuthObj.call({ method: 'changeMerchantStatus', body })
  
  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getApprovedMerchantAuthNetwork = async () => {
  const response: any = await MerchantAuthObj.call({ method: 'getApprovedMerchants' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getRejectedMerchantAuthNetwork = async () => {
  const response: any = await MerchantAuthObj.call({ method: 'getRejectedMerchants' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}
