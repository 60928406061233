import react, { useState, useEffect } from 'react'
import { Form, Upload, Input, message, Select, InputNumber } from 'antd';
import { Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

export const editLineForContentManager = (name: string, selectedData: any = {}, listValues?: any): any => {
  if (name === 'trainings') {
    return <Tranings selectedData={selectedData} />
  }

  if (name === 'quiz') {
    return <Quiz selectedData={selectedData} />
  }

  if (name === 'questions') {
    return <Questions selectedData={selectedData} />
  }

  if (name === 'didYouKnow') {
    return <DidYouKnow selectedData={selectedData} />
  }
}

const Tranings = ({ selectedData }: any) => {
  const [fileList, setFileList] = useState<any>([]);
  const [selectedType, setSelectedType] = useState();
  const selectBefore = (
    <Select defaultValue="http://" className="select-before">
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  useEffect(() => {
    if (selectedData.image) {
      setFileList([{ url: selectedData.image }])
    }
  }, []);


  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const props = {
    name: 'file',
    customRequest: dummyRequest,
    multiple: false,
    beforeUpload: (file: any) => {
      const isCorretType = ['image/png', 'image/jpeg'].includes(file.type);
      if (!isCorretType) {
        message.error(`${file.name} is not a png of jpg file`);
      }
      return isCorretType || Upload.LIST_IGNORE;
    },
    onChange: async (info: any) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList)
    },
    onRemove: async () => {
      setFileList([])
    }
  };

  return (
    <>
      <Form.Item
        label='Type'
        name='type'
        rules={[{ required: true }]}
      >
        <Select onChange={(value) => setSelectedType(value)} disabled={selectedData.type}>
          <Option value="Video">Video</Option>
          <Option value="Text">Text</Option>
        </Select>
      </Form.Item>

      {(selectedData.type === 'Video' || selectedType === 'Video') &&
        <Form.Item
          label='Url'
          name='url'
          rules={[{ required: true }]}
        >
          <Input addonBefore={selectBefore} />
        </Form.Item>}
      {(selectedData.type === 'Text' || selectedType === 'Text') && <>
        <Form.Item
          label='Title'
          name='title'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Body'
          name='body'
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
        >
          <Upload listType="picture" fileList={fileList} {...props} >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </>}

    </>
  )
}

const Quiz = ({ selectedData }: any) => {
  const [takenAnswers, setTakenAnswers] = useState<any>({});
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0) {
      let newObject: any = {}
      selectedData.answers.map((item: string, index: number) => { newObject[index] = item })
      setTakenAnswers(newObject)
      if (selectedData.image) {
        setFileList([{ url: selectedData.image }])
      }
    }
  }, []);

  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const props = {
    name: 'file',
    customRequest: dummyRequest,
    multiple: false,
    beforeUpload: (file: any) => {
      const isCorretType = ['image/png', 'image/jpeg'].includes(file.type);
      if (!isCorretType) {
        message.error(`${file.name} is not a png of jpg file`);
      }
      return isCorretType || Upload.LIST_IGNORE;
    },
    onChange: async (info: any) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList)
    },
    onRemove: async () => {
      setFileList([])
    }
  };

  return (
    <>
      <Form.Item
        label='Question'
        name='question'
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Answers'
        name='answers'
        rules={[{ required: true }]}
      >
        <Form.List name='answers'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={name}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input
                      onChange={(e) => {
                        let myObject = { ...takenAnswers }
                        myObject[key] = e.target.value
                        setTakenAnswers(myObject)
                      }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => {
                    let myObject = { ...takenAnswers }
                    remove(name)
                    delete myObject[key]
                    setTakenAnswers(myObject)
                  }} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        label='Correct Answer Index'
        name='correctAnswerIndex'
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select Correct Answer"
          allowClear
        >
          <>
            {Object.keys(takenAnswers)?.map((item, index) => <Option value={index}>{takenAnswers[item]}</Option>)}
          </>
        </Select>
      </Form.Item>
      <Form.Item
        label='Duration (seconds)'
        name='duration'
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label='Points'
        name='points'
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="image"
        label="Image"
      >
        <Upload listType="picture" fileList={fileList} {...props} >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
    </>
  )
}

const Questions = ({ selectedData }: any) => {
  const [selectedType, setSelectedType] = useState();

  return (
    <>
      <Form.Item
        label='Type'
        name='type'
        rules={[{ required: true }]}
      >
        <Select onChange={(value) => setSelectedType(value)} disabled={selectedData.type}>
          <Option value="multipleChoice">Multiple Choice</Option>
          <Option value="text">Text</Option>
        </Select>
      </Form.Item>

      {(selectedData.type === 'text' || selectedType === 'text') && <>
        <Form.Item
          label='Question'
          name='question'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label='Answers'
          name='answers'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item> */}
      </>}

      {(selectedData.type === 'multipleChoice' || selectedType === 'multipleChoice') && <>
        <Form.Item
          label='Question'
          name='question'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Answers'
          name='answers'
          rules={[{ required: true }]}
        >
          <Form.List name='answers'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={key}
                      rules={[{ required: true, message: 'Missing first name' }]}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </>}

    </>
  )
}

const DidYouKnow = ({ selectedData }: any) => {
  const [takenAnswers, setTakenAnswers] = useState<any>({});
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0) {
      let newObject: any = {}
      selectedData.answers.map((item: string, index: number) => { newObject[index] = item })
      setTakenAnswers(newObject)
      if (selectedData.image) {
        setFileList([{ url: selectedData.image }])
      }
    }
  }, []);

  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const props = {
    name: 'file',
    customRequest: dummyRequest,
    multiple: false,
    beforeUpload: (file: any) => {
      const isCorretType = ['image/png', 'image/jpeg'].includes(file.type);
      if (!isCorretType) {
        message.error(`${file.name} is not a png of jpg file`);
      }
      return isCorretType || Upload.LIST_IGNORE;
    },
    onChange: async (info: any) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList)
    },
    onRemove: async () => {
      setFileList([])
    }
  };

  return (
    <>
      <Form.Item
        label='Question'
        name='question'
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Answers'
        name='answers'
        rules={[{ required: true }]}
      >
        <Form.List name='answers'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={name}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input
                      onChange={(e) => {
                        let myObject = { ...takenAnswers }
                        myObject[key] = e.target.value
                        setTakenAnswers(myObject)
                      }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => {
                    let myObject = { ...takenAnswers }
                    remove(name)
                    delete myObject[key]
                    setTakenAnswers(myObject)
                  }} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        label='Correct Answer Index'
        name='correctAnswerIndex'
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select Correct Answer"
          allowClear
        >
          <>
            {Object.keys(takenAnswers)?.map((item, index) => <Option value={index}>{takenAnswers[item]}</Option>)}
          </>
        </Select>
      </Form.Item>
      <Form.Item
        label='Points'
        name='points'
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="image"
        label="Image"
      >
        <Upload listType="picture" fileList={fileList} {...props} >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
    </>
  )
}