import React, { useEffect, useState } from 'react'
import { TableWithDragableRowProps } from '../types'
import { Table, Modal, Form, Button, Row, Skeleton } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { setLoading } from '../../../redux/content-manager';
import { useAppDispatch } from '../../../redux/hooks';
import { arrayMoveImmutable } from 'array-move';
import { columnGenerator } from '../../../utils/functions/tableFunctions'
import { editLineForContentManager } from './FormItemRenderer';
import { getBase64 } from '../../../utils/functions/general';
import { setFileNetwork } from '../network';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export default function Content({ record, setTableData, tableData }: TableWithDragableRowProps) {
  let { name } = record;
  const dispatch = useAppDispatch()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [table, setTable] = useState<any>();
  const [columns, setColumns] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>();
  const [addModalVisibility, setAddModalVisibility] = useState<any>();
  const [modalLoading, setModalLoading] = useState<boolean>();

  const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

  // generate columns and set initial table data
  useEffect(() => {
    if (tableData && name) {
      let table = tableData[name].map((item: any, index: number) => {
        return {
          ...item,
          index: index
        }
      })
      setTable(table)
    }

    setColumns(
      columnGenerator(  // dynamic column generator
        tableData[name],
        {
          changeable: true,
          editFunction: {
            selectedData: selectedData,
            setSelectedData: setSelectedData,
            deleteFunction: deleteFunction
          }
        },
        {
          draggable: true,
          DragHandle: DragHandle
        }
      ))
  }, [tableData]);

  useEffect(() => {
    form.setFieldsValue(selectedData)
  }, [selectedData]);

  // Drag drop
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(table), oldIndex, newIndex).filter(
        el => !!el,
      );

      // remove index values in object to set root
      let newOverAllTable = newData.map((item: any) => {
        delete item.index
        return item
      })

      setTableData({ ...tableData, [name]: newOverAllTable })
      setTable(newData)
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = table && table.findIndex((x: any) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  // Edit modal cancel button
  const handleCancel = () => {
    setSelectedData(null)
    form.setFieldsValue({})
  };

  //Edit modal onFinish
  const onFinish = async (values: any) => {
    setModalLoading(true)
    let imageUrl
    let myNewData = [...tableData[name]]

    if (values?.image?.fileList?.length > 0) {
      let myBase64Foto: any = await getBase64(values.image.fileList[0].originFileObj)
      imageUrl = await setFileNetwork(myBase64Foto.split(';base64,')[1])
      values.image = imageUrl?.data.url
    }

    if (values?.image?.url) {
      imageUrl = values.image.url
      values.image = imageUrl?.data.url
    }

    let newSelectedData = { ...selectedData, ...values }
    var index = table.map((e: any) => { return e.index }).indexOf(newSelectedData.index);

    myNewData[index] = newSelectedData;

    // remove index values in object to set root
    let newOverAllTable = myNewData.map((item: any) => {
      delete item.index
      return item
    })

    setTableData({ ...tableData, [name]: newOverAllTable })
    form.setFieldsValue({})
    setSelectedData(null)
    setModalLoading(false)
  }

  // Add new data
  const onFinish2 = async (values: any) => {
    setModalLoading(true)
    let imageUrl
    let myTableData = [...tableData[name]]

    if (values?.image?.fileList[0]) {
      let myBase64Foto: any = await getBase64(values.image.fileList[0].originFileObj)
      imageUrl = await setFileNetwork(myBase64Foto.split(';base64,')[1])
      values.image = imageUrl?.data.url
    }

    myTableData.push(values)
    // remove index values in object to set root
    let newOverAllTable = myTableData.map((item: any) => {
      delete item.index
      return item
    })

    if (values.answers && !Array.isArray(values.answers)) {
      values.answers = [values.answers]
    }

    setTableData({ ...tableData, [name]: newOverAllTable })
    form2.resetFields();
    setAddModalVisibility(false)
    setModalLoading(false)
  }

  //Delete row
  const deleteFunction = (values: any, data: any) => {
    data.splice(values.index, 1)
    setTableData({ ...tableData, [name]: data })
  }

  // Table Footer -> add button
  const addButtonFooter = () => {
    form2.setFieldsValue({})
    return <Row>
      <Button style={{ margin: 0 }} type="link" onClick={() => setAddModalVisibility(true)}>
        Add New
      </Button>
    </Row>
  }

  return <>
    <Table
      size='small'
      bordered
      pagination={false}
      dataSource={table}
      columns={columns}
      rowKey="index"
      footer={addButtonFooter}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />

    <Modal width={1050} title="Edit " visible={selectedData} onOk={form.submit} onCancel={handleCancel}>
      {modalLoading
        ? <Skeleton />
        : <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {selectedData &&
            editLineForContentManager(name, selectedData)
          }
        </Form>
      }
    </Modal>

    <Modal width={1050} title="New Row" visible={addModalVisibility} onOk={form2.submit} onCancel={() => { setAddModalVisibility(false) }}>
      {modalLoading
        ? <Skeleton />
        : <Form
          form={form2}
          name="basic2"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish2}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {editLineForContentManager(name)}
        </Form>
      }
    </Modal>
  </>
}