import React, { useState, useEffect } from 'react'
import { getPendingUsersNetwork, getApprovedUsersNetwork, getRejectedUsersNetwork } from './network'
import { Select, PageHeader, Space, Table, Button, Tag, Form, Divider } from 'antd';
import { rio } from '../../utils/RBS'

const { Option } = Select;

export default function UserManager() {
  const [statusType, setStatusType] = useState<String>('pending')
  const [pendingUser, setPendingUser] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getPendingUsers()
  }, [])

  const handleChange = (value: string) => {
    setStatusType(value)
    if (value === "rejected") getRejectedUsers()
    if (value === "approved") getApprovedUsers()
    if (value === "pending") getPendingUsers()
  };

  const getPendingUsers = async () => {
    setLoading(true)
    let pendingUsers = await getPendingUsersNetwork()
    if (pendingUsers.data) {
      setPendingUser(pendingUsers.data)
      setLoading(false)
    }
  }

  const getApprovedUsers = async () => {
    setLoading(true)
    let pendingUsers = await getApprovedUsersNetwork()
    if (pendingUsers.data) {
      setPendingUser(pendingUsers.data)
      setLoading(false)
    }
  }

  const getRejectedUsers = async () => {
    setLoading(true)
    let pendingUsers = await getRejectedUsersNetwork()
    if (pendingUsers.data) {
      setPendingUser(pendingUsers.data)
      setLoading(false)
    }
  }

  const approveUser = async (record: any) => {
    setLoading(true)
    let UserCloudObj = await rio.getCloudObject({
      classId: 'User',
      instanceId: record.id
    })

    const response: any = await UserCloudObj.call({
      method: 'changeStatus',
      body: { status: 'approved' }
    })

    if (response.status.toString().includes('2')) {
      getPendingUsers()
      setLoading(false)
    }
  }

  const rejectUser = async (record: any) => {
    setLoading(true)
    let UserCloudObj = await rio.getCloudObject({
      classId: 'User',
      instanceId: record.id
    })

    const response: any = await UserCloudObj.call({
      method: 'changeStatus',
      body: { status: 'rejected' }
    })

    if (response.status.toString().includes('2')) {
      getPendingUsers()
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'msisdn',
      key: 'msisdn',
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: any) => {
        let color = 'green'
        if (status === 'pending') { color = 'geekblue' }
        if (status === 'rejected') { color = 'volcano' }

        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      }
    },
  ];

  const actions: any = [{
    title: 'Action',
    key: 'action',
    render: (record: any) => (
      <Space size="middle">
        <Button
          onClick={() => approveUser(record)}
          style={{ color: 'green', borderColor: 'green' }}
        >
          Approve
        </Button>
        <Button
          onClick={() => rejectUser(record)}
          danger
          ghost
        >
          Reject
        </Button>
      </Space>
    ),
  }]

  return (
    <div>
      <PageHeader title="User Manager" />
      <Form.Item label="Status" style={{ marginLeft: 24 }}>
        <Select defaultValue="pending" style={{ width: 220 }} onChange={handleChange}>
          <Option value="pending">Pending</Option>
          <Option value="rejected">Rejected</Option>
          <Option value="approved">Approved</Option>
        </Select>
      </Form.Item>
      <Table
        loading={loading}
        columns={statusType === 'pending' ? columns.concat(actions) : columns}
        dataSource={pendingUser}
      />
    </div>
  )
}