import React, { useState } from "react";
import { Card, Col } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { IColorItem } from "../type";

interface Props {
  selectedColorChart: IColorItem[];
  index: number;
  type: string;
  item: IColorItem;
  selectColorHandler: (item: IColorItem) => void;
}

export const ColorCard = (props: Props) => {
  const { selectedColorChart, item, index, type, selectColorHandler } = props;
  const selected = selectedColorChart.find((color) => color.id === item.id);

  const [hovered, setHovered] = useState(false);

  const gridStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "center",
    position: "relative",
    backgroundColor: item.hexCode,
    boxSizing: "border-box",
    outline: selected && type === "colorList" ? "solid 2px #4bb542" : "none",
  };

  return (
    <Col
      span={4}
      key={`${type}_${index}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card style={gridStyle}>
        {item.colorLabel} <br />
        {item.colorCode}
        {(hovered && type === "colorList") ? (
          <CheckCircleOutlined
            onClick={() => selectColorHandler(item)}
            style={{
              position: "absolute",
              cursor: "pointer",
              top: 10,
              right: 10,
              zIndex: 5,
            }}
          />
        ) : null}
        {(hovered && type !== "colorList") ? (
          <CloseCircleOutlined
            onClick={() => selectColorHandler(item)}
            style={{
              position: "absolute",
              cursor: "pointer",
              top: 10,
              right: 10,
              zIndex: 5,
            }}
          />
        ) : null}
        {/* {selected && type === "colorList" ? (
          <CheckCircleOutlined
            style={{
              color: "#4bb542",
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 5,
            }}
          />
        ) : null} */}
      </Card>
    </Col>
  );
};
