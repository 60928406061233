import React, { useEffect, useState } from 'react'
import { Card, Button, Upload, message, Row, Progress, Table, Tooltip, Typography } from 'antd'
import { rio } from '../../../utils/RBS'
import { CopyOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { triggerExportNetwork } from '../network'
import { fileToArrayBuffer, bufferToBase64 } from '../../../utils/functions/general'
import { importPricesNetwork } from '../network'
import { gzipSync } from 'fflate'
import moment from 'moment'

const { Title, Text } = Typography

export default function Product() {
    const [exportData, setExportData] = useState([])
    const [importData, setImportData] = useState([])

    useEffect(() => {
        subscribeExportState()
    }, [])

    const subscribeExportState = async () => {
        const cloudObject = await rio.getCloudObject({
            classId: 'ProductManager',
        })
        cloudObject.state?.public.subscribe(state => {
            let priceImports = state.imports.filter((item: any) => item.type === 'price')
            let priceExports = state.exports.filter((item: any) => !['product', 'color'].includes(item.type)).sort((a: any, b: any) => b.createdAt - a.createdAt)
            setImportData(priceImports.reverse())
            setExportData(priceExports)
        })
    }

    const copyClipboard = (value: string): void => {
        message.success('Url copy to clipboard')
        navigator.clipboard.writeText(value)
    }

    const downloadFile = (fileUrl: string): void => {
        window.location.href = fileUrl
    }

    const startExport = async () => {
        try {
            await triggerExportNetwork('price')
        } catch (err: any) {
            if (err.response?.data?._friendlyMessage) {
                alert(err.response.data._friendlyMessage.message)
            } else {
                message.error('Error')
            }
        }
    }

    const columns = [
        {
            title: 'Process Id',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: 'Start Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (createdAt: string) => {
                let startDate = moment(createdAt).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <>
                        <span>{startDate}</span>
                    </>
                )
            },
        },
        {
            title: 'Finish Date',
            dataIndex: 'finishedAt',
            width: '15%',
            render: (finishedAt: string) => {
                let finishDate = moment(finishedAt).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <>
                        <span>{finishDate}</span>
                    </>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status: string, row: any) => {
                return (
                    <>
                        {status === 'started' ? (
                            <Progress percent={Number(((row.scannedItemCount / row.itemCount) * 100).toFixed(0))} />
                        ) : (
                            <Tooltip placement="top" title={row.error || undefined}>
                                <Text type={status === 'completed' ? 'success' : 'danger'}>{status.toUpperCase()}</Text>
                            </Tooltip>
                        )}
                    </>
                )
            },
        },
        {
            title: 'Url',
            dataIndex: 'fileURL',
            key: 'fileURL',
            width: '25%',
            render: (fileUrl: string) => {
                return (
                    <>
                        <Tooltip placement="top" title={fileUrl || undefined}>
                            {fileUrl && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => copyClipboard(fileUrl)}>
                                    <CopyOutlined />
                                    <Text style={{ marginLeft: 8, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{fileUrl}</Text>
                                </div>
                            )}
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: 'Download',
            dataIndex: 'fileURL',
            key: 'fileURL',
            width: '10%',
            render: (fileURL: string, row: any) => {
                return (
                    <>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadFile(fileURL)} disabled={row.status !== 'completed'}>
                            Download
                        </Button>
                    </>
                )
            },
        },
    ]

    const importColumns = [
        {
            title: 'Process Id',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: 'Start Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (createdAt: string) => {
                let startDate = moment(createdAt).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <>
                        <span>{startDate}</span>
                    </>
                )
            },
        },
        {
            title: 'Finish Date',
            dataIndex: 'finishedAt',
            width: '15%',
            render: (finishedAt: string) => {
                let finishDate = moment(finishedAt).format('DD/MM/YYYY HH:mm:ss')
                return (
                    <>
                        <span>{finishDate}</span>
                    </>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status: string, row: any) => {
                return (
                    <>
                        {status === 'started' ? (
                            <Progress percent={Number(((row.scannedItemCount / row.itemCount) * 100).toFixed(0))} />
                        ) : (
                            <Tooltip placement="top" title={row.error || undefined}>
                                <Text type={status === 'completed' ? 'success' : 'danger'}>{status.toUpperCase()}</Text>
                            </Tooltip>
                        )}
                    </>
                )
            },
        },
    ]
    // dummy request for ant design upload component
    const dummyRequest = async ({ file, onSuccess }: any) => {
        const arrayBf: any = await fileToArrayBuffer(file) // get ArrayBuffer with reader.readAsArrayBuffer()
        const nonZippedArray = new Uint8Array(arrayBf) // change data to Uint8Array
        const gzipped = await gzipSync(nonZippedArray) // gzip file
        const base64Zipped = await bufferToBase64(gzipped) // convert zipped file to base64

        const zippedDataForImport = { content: base64Zipped }
        importPricesNetwork(zippedDataForImport)
    }

    // upload component props
    const uploadProps = {
        name: 'file',
        showUploadList: false,
        customRequest: dummyRequest,
    }

    return (
        <div style={{ marginTop: 18 }}>
            <Card>
                <Row justify="space-between">
                    <Title level={4}>Import</Title>
                    <Upload {...uploadProps}>
                        <Button type="dashed" icon={<UploadOutlined />}>
                            Import
                        </Button>
                    </Upload>
                </Row>
                <Table size="small" scroll={{ y: 550 }} rowKey={obj => obj.id} pagination={false} columns={importColumns} dataSource={importData} />
            </Card>

            <Card style={{ marginTop: 8 }}>
                <Row justify="space-between">
                    <Title level={4}>Exports</Title>
                    <Button type="dashed" onClick={() => startExport()} icon={<DownloadOutlined />}>
                        Export
                    </Button>
                </Row>
                <Table size="small" scroll={{ y: 350 }} rowKey={obj => obj.id} pagination={false} columns={columns} dataSource={exportData} />
            </Card>
        </div>
    )
}
