import moment from 'moment'
import { rio } from '../../utils/RBS'

export const getUsers = async (startDate: Date, endDate: Date) => {
    let User = await rio.getCloudObject({
        classId: 'Manager',
        instanceId: 'default',
        useLocal: true,
    })

    const response: any = await User.call({
        method: 'getMerchantAndUser',
        body: {
            whoIs: 'user',
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        },
    })

    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getMerchants = async (startDate: Date, endDate: Date) => {
    let User = await rio.getCloudObject({
        classId: 'Manager',
        instanceId: 'default',
        useLocal: true,
    })

    const response: any = await User.call({
        method: 'getMerchantAndUser',
        body: {
            whoIs: 'merchant',
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        },
    })

    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getRewards = async (minDate: number, maxDate: number) => {
    const cloudObject = await rio.getCloudObject({
        classId: 'Manager',
        instanceId: 'default',
        useLocal: true,
    })

    const response: any = await cloudObject.call({
        method: 'exportRewards',
        body: {
            minDate,
            maxDate
        },
    })

    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}
