import React, { useState, useEffect } from 'react'
import { List, Row, Breadcrumb, Collapse, Typography, Button, PageHeader, Popover, Descriptions } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import TableWithDragableRow from './components/TableWithDragableRow';
import { upsertSurvey, upsertTraining } from '../../redux/content-manager';
import Loading from '../../components/Loading';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Content as ContentType } from './types';
import { capitilizer } from '../../utils/functions/general';

const { Panel } = Collapse;
const { Title } = Typography;

export default function Content() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch()
  let navigate = useNavigate();

  const entities = useAppSelector(state => state.contentManager.training.data)
  const surveys = useAppSelector(state => state.contentManager.surveys.data)
  const didYouKnow = useAppSelector(state => state.contentManager.didYouKnow.data)
  const loading = useAppSelector(state => state.contentManager.loading)

  const [tableData, setTableData] = useState<ContentType>({} as ContentType)

  // Initial set for selected one
  useEffect(() => {
    let overAllData = entities?.find((item: ContentType) => params.content?.includes(item.id))
    let overAllData2 = surveys?.find((item: ContentType) => params.content?.includes(item.id))
    let overAllData3 = didYouKnow?.find((item: ContentType) => params.content?.includes(item.id))

    if (location.pathname.split('/')[2] === 'training') {
      setTableData(overAllData)
    }
    if (location.pathname.split('/')[2] === 'surveys') {
      setTableData(overAllData2)
    }
    if (location.pathname.split('/')[2] === 'didyouknow') {
      setTableData(overAllData3)
    }

  }, []);

  // Render Panels
  const panelKeys = ['trainings', 'quiz', 'questions']
  const createPanel = () => {
    let myKeys: string[] = Object.keys(tableData).filter(item => panelKeys.includes(item))
    return myKeys.map((item: any, index: number) => {
      return <Panel header={capitilizer(item)} key={index + 1}>
        <TableWithDragableRow record={{ name: item, key: index }} tableData={tableData} setTableData={setTableData} />
      </Panel>
    })
  }

  // Render Information
  const informationKeys = [
    'image', 'title', 'description',
    'shortDescription', 'points', 'duration',
    'documents', 'id', 'question',
    'answers', 'correctAnswerIndex'
  ]

  const createInformation = () => {
    let myKeys: string[] = Object.keys(tableData).filter(item => informationKeys.includes(item))
    return myKeys.map((item: string, index: number) => {
      if (item === 'image') {
        return <Descriptions.Item label={capitilizer(item)}> <img width={100} src={tableData[item]} /> </Descriptions.Item>
      }
      if (item === 'documents') {
        //@ts-ignore
        return <Descriptions.Item label={capitilizer(item)}>
          <List
            bordered
            //@ts-ignore
            dataSource={tableData[item]}
            renderItem={(item: any) => (
              <List.Item>
                <a href={item.url} target="_blank">{item.label}</a>
              </List.Item>
            )}
          />
        </Descriptions.Item>
      }
      if (item === 'answers') {
        return <Descriptions.Item label={capitilizer(item)}>
          <List
            bordered
            //@ts-ignore
            dataSource={tableData[item]}
            renderItem={(item: any) => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        </Descriptions.Item>
      }
      return <Descriptions.Item label={capitilizer(item)}>{tableData[item]}</Descriptions.Item>
    })
  }

  // Render Breadcrumb 
  const breadcrumbRenderer = (urlArray: string[]) => {
    return urlArray.map((item) => {
      return <Breadcrumb.Item>
        {item}
      </Breadcrumb.Item>
    })
  }

  const save = () => {
    // Save survey
    if (location.pathname.split('/')[2] === 'surveys') {
      dispatch(upsertSurvey(tableData))
    }
    // Save training
    if (location.pathname.split('/')[2] === 'training') {
      dispatch(upsertTraining(tableData))
    }
  }

  // Check is content exist or not
  const isInContentManger = () => {
    let isInContent
    if (location.pathname.split('/')[2] === 'training') {
      isInContent = entities.find((item: ContentType) => { // look in redux
        return location.pathname.split('/').includes(item.id)
      })
    }
    if (location.pathname.split('/')[2] === 'surveys') {
      isInContent = surveys.find((item: ContentType) => { // look in redux
        return location.pathname.split('/').includes(item.id)
      })
    }
    if (location.pathname.split('/')[2] === 'didyouknow') {
      isInContent = didYouKnow.find((item: ContentType) => { // look in redux
        return location.pathname.split('/').includes(item.id)
      })
    }

    return isInContent ? true : false
  }

  return (
    <div style={{ position: 'relative' }}>
      {isInContentManger() ?
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => { navigate(`/content-manager/${location.pathname.split('/')[2]}`) }}
            title={tableData?.title || tableData?.question}
            subTitle={<>
              {tableData?.description?.length > 25 ?
                <Popover content={<div style={{ width: 240 }}>{tableData?.description}</div>} trigger="hover">
                  <div>{tableData?.description.substring(0, 25) + '...'}</div>
                </Popover> : <div>{tableData?.description}</div>}
            </>}
            extra={[
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumb>
                  {breadcrumbRenderer(location.pathname.split('/'))}
                </Breadcrumb>
                <Button
                  disabled={loading}
                  onClick={save}
                  style={{ marginLeft: 20, borderRadius: 5, borderColor: '#1890ff', color: '#1890ff' }}
                >Save</Button>
              </Row>
            ]}
          />
          {loading
            ? <div style={{
              display: 'flex',
              position: 'absolute',
              margin: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              zIndex: 2,
            }}><Loading /></div>
            : <>
              <Collapse>
                <Panel header={'Information'} key={0}>
                  <Descriptions layout="vertical" bordered>
                    {createInformation()}
                  </Descriptions>
                  {/* <Button onClick={() => { setAddContentModalVisibility(true) }} style={{ width: '100%', marginTop: 10 }} type='dashed'>Edit</Button> */}
                </Panel>
                {createPanel()}
              </Collapse>
              <Row justify='end' style={{ marginTop: 18 }}>
                <Button
                  disabled={loading}
                  onClick={save}
                  style={{ marginLeft: 20, borderRadius: 5, borderColor: '#1890ff', color: '#1890ff' }}
                >Save</Button>
              </Row>
            </>

          }
        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
          <div style={{ textAlign: 'center' }}>
            <Title style={{ color: '#8c8c8c' }} level={3}>
              This content was not found!
            </Title>
            <Button type='dashed' onClick={() => { navigate(`/content-manager/${location.pathname.split('/')[2]}`) }}>
              <ArrowLeftOutlined />Back To Content Manager
            </Button>
          </div>
        </div>
      }
    </div >
  )
}