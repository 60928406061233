import React, { useEffect, useState } from "react";
import { Card, Input, Button, Row, Tabs } from "antd";
import {
  getColorList,
  getFeaturedColorList,
  setFeaturedColorList,
} from "../network";
import { SearchOutlined, SaveOutlined } from "@ant-design/icons";

import { ColorCard } from "../components/ColorCard";
import { IColorList, IColorItem } from "../type";

const ColorLib = require("color");
const { TabPane } = Tabs;

enum CategroryId {
  DIS_CEPHE = "discephe-9L6B2",
  IC_CEPHE = "iccephe-BL0R1",
  AHSAP = "ahsap-BMB5S",
}

export default function FeaturedColors() {
  const [colorChart, setColorChart] = useState<IColorItem[]>([]);
  const [value, setValue] = useState("");
  const [categoryId, setCategoryId] = useState<string>(CategroryId.DIS_CEPHE);
  const [selectedColorChart, setSelectedColorChart] = useState<IColorItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getColorListHandler();
    getFeaturedColorListHandler();
  }, []);

  const selectorColorList = (colorList: IColorList) => {
    return Object.entries(colorList).map(([, value]) => {
      const mClone: IColorItem = {
        id: value.id,
        hexCode: value.hexCode || ColorLib(Number(value.rgbCode)).hex(),
        colorCode: value.id,
        colorLabel: value.name || value.colorLabel,
        baseCode: value.baseCode,
        categoryId: value.categoryId,
        prices: value.prices,
      };

      return mClone;
    });
  };

  const getColorListHandler = async () => {
    const response = await getColorList({});
    if (response.status) {
      const colors = selectorColorList(response.data);
      setColorChart(colors);
    }
  };

  const getFeaturedColorListHandler = async () => {
    const response = await getFeaturedColorList({});
    if (response.status) {
      const colors = selectorColorList(response.data);
      setSelectedColorChart(colors);
    }
  };

  const selectColorHandler = (item: IColorItem) => {
    const findColor = selectedColorChart.find(
      (color) => color.id === item.id && color.categoryId === categoryId
    );
    if (findColor) {
      const newColorChart = selectedColorChart.filter(
        (color) => color.id !== item.id
      );
      setSelectedColorChart(newColorChart);
    } else {
      setSelectedColorChart([...selectedColorChart, { ...item, categoryId }]);
    }
  };

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onSaveHandler = async () => {
    const body = {
      featuredColors: selectedColorChart,
    };
    const response = await setFeaturedColorList(body);
    if (response.status) {
      selectorColorList(response.data);
    }
  };

  const onCategoryChange = (value: string) => {
    setCategoryId(value);
  };

  const renderFeaturedColorList = (category: string) => {
    return (
      <Row gutter={[8, 16]}>
        {selectedColorChart
          ?.filter((featured: any) => featured.categoryId === category)
          .map((item, index) => (
            <ColorCard
              item={item}
              index={index}
              type="selectedColorList"
              selectedColorChart={selectedColorChart}
              selectColorHandler={selectColorHandler}
            />
          ))}
      </Row>
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "12px",
        }}
      >
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={onSaveHandler}
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </div>

      <Card title="Featured Color List">
        <Tabs defaultActiveKey="1" onChange={onCategoryChange}>
          <TabPane tab="Dis Cephe" key={CategroryId.DIS_CEPHE}>
            {renderFeaturedColorList("discephe-9L6B2")}
          </TabPane>
          <TabPane tab="Ic Cephe" key={CategroryId.IC_CEPHE}>
            {renderFeaturedColorList("iccephe-BL0R1")}
          </TabPane>
          <TabPane tab="Ahsap" key={CategroryId.AHSAP}>
            {renderFeaturedColorList("ahsap-BMB5S")}
          </TabPane>
        </Tabs>
      </Card>

      <Card title="Color List">
        <Input
          size="large"
          placeholder="Search Colour by Name"
          style={{ margin: 10, width: "98%" }}
          onChange={onChangeHandler}
          suffix={<SearchOutlined />}
        />
        <Row gutter={[8, 16]}>
          {colorChart
            ?.filter((item) =>
              item.colorLabel.toLowerCase().includes(value.toLowerCase())
            )
            .map((item, index) => (
              <ColorCard
                item={item}
                index={index}
                type="colorList"
                selectedColorChart={selectedColorChart}
                selectColorHandler={selectColorHandler}
              />
            ))}
        </Row>
      </Card>
    </>
  );
}
