import moment from "moment"
import { Cart, CartItem } from "./type";
import { Tag } from "antd";

const ColorLib = require("color");

export const columns = [
  {
    title: "Order ID",
    dataIndex: "orderId",
    key: "id",
  },
  {
    title: "Merchant ID",
    dataIndex: "merchantId",
    key: "merchantId",
  },
  {
    title: "Merchant Name",
    dataIndex: "merchantName",
    key: "merchantName",
  },
  {
    title: "User Name",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "User Phone Number",
    dataIndex: "userPhoneNumber",
    key: "userPhoneNumber",
  },
  {
    title: "Total Price",
    dataIndex: "cart",
    key: "totalPrice",
    render: (value: Cart) => {
      return <span>{value?.totalPrice + " ₺"}</span>
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: string) => {
      let startDate = moment(createdAt).format("DD/MM/YYYY HH:mm:ss");
      return <span>{startDate}</span>;
    },
  },
  {
    title: "Order Status",
    dataIndex: "orderStatus",
    key: "orderStatus",
  },
];

export const orderDetailColumns = [
  {
    title: "Sku",
    key: "sku",
    dataIndex: "sku",
  },
  {
    title: "Name",
    key: "name",
    render: (record: CartItem) => {
      return record?.productInfo?.name;
    },
  },
  {
    title: "Image",
    key: "image",
    render: (record: CartItem) => {
      return <img width={50} src={record?.productInfo?.images?.[0]?.url} alt="" />;
    },
  },
  {
    title: "Unit",
    key: "unit",
    render: (record: CartItem) => {
      return record?.productInfo?.unit;
    },
  },

  {
    title: "Qty",
    key: "qty",
    dataIndex: "qty",
  },
  {
    title: "Liter",
    key: "liter",
    dataIndex: "liter",
  },
  {
    title: "Color",
    key: "color",
    render: (record: CartItem) => {
      return (
        <Tag
          style={{ color: "#000" }}
          color={ColorLib(Number(record?.colorInfo?.rgbCode))?.hex()}
        >
          {record?.colorInfo?.id + "-" + record?.colorInfo?.name}
        </Tag>
      );
    },
  },
  {
    title: "Color Price",
    key: "colorPrice",
    render: (record: CartItem) => {
      return record?.colorPrice + " ₺";
    },
  },
  {
    title: "Base Price",
    key: "basePrice",
    render: (record: CartItem) => {
      return record?.basePrice + " ₺";
    },
  },
  {
    title: "Price",
    key: "price",
    render: (record: CartItem) => {
      return record?.price + " ₺";
    },
  },
  {
    title: "Point Multiplier",
    key: "pointMultiplier",
    dataIndex: "pointMultiplier",
  },
  {
    title: "Jotun Point",
    key: "jotunPoint",
    dataIndex: "jotunPoint",
  },
];