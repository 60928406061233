import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import {
    getTrainingsNetwork,
    upsertTrainingNetwork,
    deleteTrainingNetwork,
    getSurveysNetwork,
    deleteSurveyNetwork,
    upsertSurveyNetwork,
    getDidYouKnowNetwork,
    upsertDidYouKnowNetwork,
    deleteDidYouKnowNetwork,
} from '../routes/ContentManager/network'

//Constants
const GET_TRAININGS = 'get/getTranings'
const UPSERT_TRAINING = 'get/upsertTranings'
const DELETE_TRAINING = 'get/deleteTranings'

const GET_SURVEYS = 'get/getSurveys'
const UPSERT_SURVEY = 'get/upsertSurvey'
const DELETE_SURVEY = 'get/deleteSurvey'

const GET_DID_YOU_KNOW = 'get/getDidYouKnow'
const UPSERT_DID_YOU_KNOW = 'get/upsertDidYouKnow'
const DELETE_DID_YOU_KNOW = 'get/deleteDidYouKnow'

// Define a type for the slice state
interface CounterState {
    value: number
    training: any
    surveys: any
    didYouKnow: any
    loading: boolean
}

// Define the initial state using that type
const initialState: CounterState = {
    value: 0,
    training: [],
    surveys: [],
    didYouKnow: [],
    loading: false,
}

// Async Functions
export const getTrainings: any = createAsyncThunk(GET_TRAININGS, async thunkAPI => {
    let res = await getTrainingsNetwork().then(contentDatas => contentDatas)
    return res
})

export const upsertTraining: any = createAsyncThunk(UPSERT_TRAINING, async body => {
    let res = await upsertTrainingNetwork(body)
    return res
})

export const deleteTraining: any = createAsyncThunk(DELETE_TRAINING, async (id: string) => {
    let res = await deleteTrainingNetwork(id)
    return res
})

export const getSurveys: any = createAsyncThunk(GET_SURVEYS, async thunkAPI => {
    let res = await getSurveysNetwork().then(contentDatas => contentDatas)
    return res
})

export const getDidYouKnow: any = createAsyncThunk(GET_DID_YOU_KNOW, async thunkAPI => {
    let res = await getDidYouKnowNetwork().then(contentDatas => contentDatas)
    return res
})

export const upsertDidYouKnow: any = createAsyncThunk(UPSERT_DID_YOU_KNOW, async body => {
    let res = await upsertDidYouKnowNetwork(body).then(contentDatas => contentDatas)
    return res
})

export const deleteDidYouKnow: any = createAsyncThunk(DELETE_DID_YOU_KNOW, async (id: string) => {
    let res = await deleteDidYouKnowNetwork(id)
    return res
})

export const upsertSurvey: any = createAsyncThunk(UPSERT_SURVEY, async (body: any) => {
    let res = await upsertSurveyNetwork({ ...body, surveyType: 'popup' })
    return res
})

export const deleteSurvey: any = createAsyncThunk(DELETE_SURVEY, async (id: string) => {
    let res = await deleteSurveyNetwork(id)
    return res
})

export const counterSlice = createSlice({
    name: 'contentManager',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
    extraReducers: {
        // TRAINING
        [getTrainings.pending]: state => {
            state.loading = true
        },
        [getTrainings.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.training = payload
        },
        [getTrainings.rejected]: state => {
            state.loading = false
        },

        [upsertTraining.pending]: state => {
            state.loading = true
        },
        [upsertTraining.fulfilled]: (state, { payload }) => {
            state.loading = false
            message.success('Successful save on Training.')
        },
        [upsertTraining.rejected]: (state, { error }) => {
            state.loading = false
            message.error(error.message)
        },

        [deleteTraining.pending]: state => {
            state.loading = true
        },
        [deleteTraining.fulfilled]: state => {
            state.loading = false
            message.success('Successful deleted on Training.')
        },
        [deleteTraining.rejected]: state => {
            state.loading = false
        },

        // SURVEY
        [getSurveys.pending]: state => {
            state.loading = true
        },
        [getSurveys.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.surveys = payload
        },
        [getSurveys.rejected]: state => {
            state.loading = false
        },

        [upsertSurvey.pending]: state => {
            state.loading = true
        },
        [upsertSurvey.fulfilled]: state => {
            state.loading = false
            message.success('Successful save on Survey.')
        },
        [upsertSurvey.rejected]: (state, { error }) => {
            state.loading = false
            message.error(error.message)
        },

        [deleteSurvey.pending]: state => {
            state.loading = true
        },
        [deleteSurvey.fulfilled]: state => {
            state.loading = false
            message.success('Successful deleted on Surveys.')
        },
        [deleteSurvey.rejected]: state => {
            state.loading = false
        },

        // DID YOU KNOW
        [getDidYouKnow.pending]: state => {
            state.loading = true
        },
        [getDidYouKnow.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.didYouKnow = payload
        },
        [getDidYouKnow.rejected]: state => {
            state.loading = false
        },

        [upsertDidYouKnow.pending]: state => {
            state.loading = true
        },
        [upsertDidYouKnow.fulfilled]: state => {
            state.loading = false
            message.success('Successful save on Did You Know.')
        },
        [upsertDidYouKnow.rejected]: (state, { error }) => {
            state.loading = false
            message.error(error.message)
        },

        [deleteDidYouKnow.pending]: state => {
            state.loading = true
        },
        [deleteDidYouKnow.fulfilled]: state => {
            state.loading = false
            message.success('Successful deleted on Did You Know.')
        },
        [deleteDidYouKnow.rejected]: (state, { error }) => {
            state.loading = false
            message.error(error.message)
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoading } = counterSlice.actions

export default counterSlice.reducer
