import { useState, useEffect } from "react";
import {
  message,
  Upload,
  Skeleton,
  Button,
  Form,
  Modal,
  Input,
  DatePicker,
  Checkbox
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../utils/functions/general";
import { setFileNetwork, upsertBanner } from "../network";

const { TextArea } = Input;

interface Props {
  visible: boolean;
  hideModal: () => void;
  loading: boolean;
  data: any;
}

export const AddEditBannerModal = ({
  visible,
  hideModal,
  loading,
  data,
}: Props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) {
      setFileList([]);
    }
  }, [visible])

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      const copyData = { ...data };
      copyData.publishAt = moment(copyData.publishAt);
      copyData.expireAt = moment(copyData.expireAt);
      form.setFieldsValue(copyData);
      copyData.imageUrl &&
        setFileList([
          {
            url: copyData.imageUrl,
          },
        ]);
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [data]);

  const onFinish = async (values: any) => {
    try {
      setRequestLoading(true);
      if (fileList?.length > 0) {
        let imageUrl: any;
  
        if (!fileList[0]?.url && fileList[0]) {
          let myBase64Foto: any = await getBase64(fileList[0].originFileObj);
          const imageResponse = await setFileNetwork(myBase64Foto.split(';base64,')[1])
          imageUrl = imageResponse?.data?.url || '';
        } else {
          imageUrl = fileList[0]?.url || undefined;
        }
        values.imageUrl = imageUrl;
      } else {
        values.imageUrl = undefined;
      }
  
      const publishAtDate = values.publishAt?.toDate();
      const expireAtDate = values.expireAt?.toDate();
  
      values.publishAt = publishAtDate;
      values.expireAt = expireAtDate;
  
      if (data?.id) {
        values.id = data?.id;
      }
  
  
      const response =  await upsertBanner(values);
      if (response.data) {
        setRequestLoading(false);
        hideModal();
      } else {
        setRequestLoading(false);
        message.error("Bir Hata Oluştu");
      }  
    } catch (err) {
      setRequestLoading(false);
      message.error("Bir Hata Oluştu");
    }
    
  };

  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    customRequest: dummyRequest,
    multiple: false,
    beforeUpload: (file: any) => {
      const isCorretType = ["image/png", "image/jpeg"].includes(file.type);
      if (!isCorretType) {
        message.error(`${file.name} is not a png of jpg file`);
      }
      return isCorretType || Upload.LIST_IGNORE;
    },
    onChange: async (info: any) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      setFileList(fileList);
    },
    onRemove: async () => {
      setFileList([]);
    },
  };

  return (
    <Modal
      style={{ borderRadius: 100 }}
      width={850}
      zIndex={10000}
      title="Add New Content"
      visible={visible}
      confirmLoading={requestLoading}
      onOk={form.submit}
      okButtonProps={{ disabled: fileList?.length === 0 }}
      onCancel={hideModal}
      okText="Submit"
    >
      {loading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="imageUrl" label="Image">
            <Upload listType="picture" fileList={fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Title" name="title">
            <Input style={{ width: 250 }} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea />
          </Form.Item>
          <Form.Item label="Active" name="active" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
          <Form.Item label="Publish At" name="publishAt" rules={[{ required: true }]}>
            <DatePicker showTime={{ format: 'HH:mm'}} />
          </Form.Item>
          <Form.Item label="Expire At" name="expireAt" rules={[{ required: true }]}>
            <DatePicker showTime={{ format: 'HH:mm'}} />
          </Form.Item>
        </Form>
        
      )}
    </Modal>
  );
};
