import { ContentCloudObj, BannerCloudObj } from '../../utils/RBS'
import { Banner } from './type';

export const getBannerList = async () => {
  const response: any = await BannerCloudObj.call({ method: 'getBanners' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const upsertBanner = async (body: Banner) => {
  const response: any = await BannerCloudObj.call({ method: 'upsertBanner', body: { banner: body } })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const deleteBanner = async (id: string) => {
  const response: any = await BannerCloudObj.call({ method: 'deleteBanner', body: { id } })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const setFileNetwork = async (body: any) => {
  const response: any = await ContentCloudObj.call({ method: 'setFile', body: body })
  if (response.status.toString().includes('2')) {
      return { status: true, data: response.data }
  } else {
      return { status: false }
  }
}