import { useState, useEffect } from 'react'
import { message, Upload, Skeleton, Button, Form, Modal, Input, Space, InputNumber, Select } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../../redux/hooks'
import { getTrainings, upsertTraining, upsertSurvey, getSurveys, setLoading, upsertDidYouKnow, getDidYouKnow } from '../../../redux/content-manager'
import { UploadOutlined } from '@ant-design/icons'
import { getBase64 } from '../../../utils/functions/general'
import { setFileNetwork, upsertDidYouKnowNetwork } from '../network'

const { TextArea } = Input
const { Option } = Select

export const AddTraining = ({ addContentModalVisibility, setAddContentModalVisibility, loading, data }: any) => {
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const [fileList, setFileList] = useState<any>([])
    const [fileListDocs, setFileListDocs] = useState<any>([])

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            form.setFieldsValue(data)
            data?.documents &&
                setFileListDocs(
                    data?.documents?.map((item: any) => {
                        return { url: item.url, name: item.label }
                    })
                )
            data.image &&
                setFileList([
                    {
                        url: data.image,
                    },
                ])
        } else {
            form.resetFields()
            setFileList([])
            setFileListDocs([])
        }
    }, [data])

    const onFinish = async (values: any) => {
        dispatch(setLoading(true))
        if (Object.keys(data)?.length > 0) {
            values.id = data.id
            values.quiz = data.quiz
            values.trainings = data.trainings
            values.didYouKnow = data.didYouKnow
        } else {
            values.quiz = []
            values.trainings = []
            values.didYouKnow = []
        }

        if (fileList?.length > 0 || fileListDocs?.length > 0) {
            let imageUrl: any

            if (!fileList[0]?.url && fileList[0]) {
                let myBase64Foto: any = await getBase64(fileList[0].originFileObj)
                imageUrl = await setFileNetwork(myBase64Foto.split(';base64,')[1])
            } else {
                imageUrl = fileList[0]?.url || undefined
            }

            let imageUrls =
                fileListDocs?.map(async (doc: any) => {
                    if (!doc.url) {
                        let myBase64Doc: any = await getBase64(doc.originFileObj)
                        let imageUrl: any = await setFileNetwork(myBase64Doc.split(';base64,')[1])
                        if (imageUrl.status) {
                            return { url: imageUrl.data.url, label: doc.name }
                        }
                    } else {
                        return { url: doc.url, label: doc.name }
                    }
                }) || []

            Promise.all(imageUrls).then(imageUrls => {
                values.documents = imageUrls
                values.image = imageUrl?.data?.url

                dispatch(upsertTraining(values)).then(() => {
                    setAddContentModalVisibility(false)
                    dispatch(getTrainings())
                })
            })
        } else {
            values.documents = undefined
            values.image = undefined

            dispatch(upsertTraining(values)).then(() => {
                setAddContentModalVisibility(false)
                dispatch(getTrainings())
            })
        }
    }

    const dummyRequest = async ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const props = {
        name: 'file',
        customRequest: dummyRequest,
        multiple: false,
        beforeUpload: (file: any) => {
            const isCorretType = ['image/png', 'image/jpeg'].includes(file.type)
            if (!isCorretType) {
                message.error(`${file.name} is not a png of jpg file`)
            }
            return isCorretType || Upload.LIST_IGNORE
        },
        onChange: async (info: any) => {
            let fileList = [...info.fileList]
            fileList = fileList.slice(-1)
            setFileList(fileList)
        },
        onRemove: async () => {
            setFileList([])
        },
    }

    const props2 = {
        name: 'file2',
        customRequest: dummyRequest,
        beforeUpload: (file: any) => {
            const isCorretType = ['application/pdf'].includes(file.type)
            if (!isCorretType) {
                message.error(`${file.name} is not a pdf file`)
            }
            return isCorretType || Upload.LIST_IGNORE
        },
        onChange: async (info: any) => {
            setFileListDocs(info.fileList)
        },
    }

    return (
        <Modal
            style={{ borderRadius: 100 }}
            width={850}
            title="Add New Content"
            visible={addContentModalVisibility}
            onOk={form.submit}
            onCancel={() => {
                setAddContentModalVisibility(false)
            }}
            okText="Submit"
        >
            {loading ? (
                <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
                <Form form={form} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
                    <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <TextArea />
                    </Form.Item>
                    <Form.Item label="Short Description" name="shortDescription">
                        <TextArea />
                    </Form.Item>
                    <Form.Item label="Points" name="points">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Duration" name="duration">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Points Need To Pass Exam" name="pointsNeedToPassExam">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="image" label="Image">
                        <Upload listType="picture" fileList={fileList} {...props}>
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="documents" label="Documents">
                        <Upload fileList={fileListDocs} {...props2}>
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}

export const AddSurvey = ({ addContentModalVisibility, setAddContentModalVisibility, loading, data }: any) => {
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            form.setFieldsValue(data)
        } else {
            form.resetFields()
        }
    }, [data])

    const onFinish = (values: any) => {
        dispatch(setLoading(true))
        if (Object.keys(data)?.length > 0) {
            values.id = data.id
            values.questions = data.questions
        } else {
            values.questions = []
        }

        dispatch(upsertSurvey(values)).then(() => {
            setAddContentModalVisibility(false)
            dispatch(getSurveys())
        })
        dispatch(setLoading(true))
    }

    return (
        <Modal
            style={{ borderRadius: 100 }}
            width={850}
            title="Add New Content"
            visible={addContentModalVisibility}
            onOk={form.submit}
            onCancel={() => setAddContentModalVisibility(false)}
            okText="Submit"
        >
            {loading ? (
                <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
                <Form form={form} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
                    <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <TextArea />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}

export const AddDidYouKnow = ({ addContentModalVisibility, setAddContentModalVisibility, loading, data }: any) => {
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const [fileList, setFileList] = useState<any>([])
    const [fileListDocs, setFileListDocs] = useState<any>([])
    const [takenAnswers, setTakenAnswers] = useState<any>({})

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            form.setFieldsValue(data)
            data?.documents &&
                setFileListDocs(
                    data?.documents?.map((item: any) => {
                        return { url: item, name: item.split('id=')[1] }
                    })
                )
            data.image &&
                setFileList([
                    {
                        url: data.image,
                    },
                ])
        } else {
            form.resetFields()
            setFileList([])
            setFileListDocs([])
        }
    }, [data])

    const onFinish = async (values: any) => {
        dispatch(setLoading(true))
        if (Object.keys(data)?.length > 0) {
            values.id = data.id
            values.quiz = data.quiz
            values.trainings = data.trainings
            values.didYouKnow = data.didYouKnow
        } else {
            values.quiz = []
            values.trainings = []
            values.didYouKnow = []
        }

        if (fileList?.length > 0 || fileListDocs?.length > 0) {
            let imageUrl: any

            if (!fileList[0]?.url && fileList[0]) {
                let myBase64Foto: any = await getBase64(fileList[0].originFileObj)
                imageUrl = await setFileNetwork(myBase64Foto.split(';base64,')[1])
            } else {
                imageUrl = fileList[0]?.url || undefined
            }

            let imageUrls =
                fileListDocs?.map(async (doc: any) => {
                    if (!doc.url) {
                        let myBase64Doc: any = await getBase64(doc.originFileObj)
                        let imageUrl: any = await setFileNetwork(myBase64Doc.split(';base64,')[1])
                        if (imageUrl.status) {
                            return imageUrl.data.url
                        }
                    } else {
                        return doc.url
                    }
                }) || []

            Promise.all(imageUrls).then(imageUrls => {
                values.documents = imageUrls
                values.image = imageUrl?.data?.url

                dispatch(upsertDidYouKnow(values)).then(() => {
                    setAddContentModalVisibility(false)
                    dispatch(getDidYouKnow())
                })
            })
        } else {
            values.documents = undefined
            values.image = undefined

            dispatch(upsertDidYouKnow(values)).then(() => {
                setAddContentModalVisibility(false)
                dispatch(getDidYouKnow())
            })
        }
    }

    const dummyRequest = async ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const props = {
        name: 'file',
        customRequest: dummyRequest,
        multiple: false,
        beforeUpload: (file: any) => {
            const isCorretType = ['image/png', 'image/jpeg'].includes(file.type)
            if (!isCorretType) {
                message.error(`${file.name} is not a png of jpg file`)
            }
            return isCorretType || Upload.LIST_IGNORE
        },
        onChange: async (info: any) => {
            let fileList = [...info.fileList]
            fileList = fileList.slice(-1)
            setFileList(fileList)
        },
        onRemove: async () => {
            setFileList([])
        },
    }

    const props2 = {
        name: 'file2',
        customRequest: dummyRequest,
        beforeUpload: (file: any) => {
            const isCorretType = ['application/pdf'].includes(file.type)
            if (!isCorretType) {
                message.error(`${file.name} is not a pdf file`)
            }
            return isCorretType || Upload.LIST_IGNORE
        },
        onChange: async (info: any) => {
            setFileListDocs(info.fileList)
        },
    }

    return (
        <Modal
            style={{ borderRadius: 100 }}
            width={850}
            title="Add New Content"
            visible={addContentModalVisibility}
            onOk={form.submit}
            onCancel={() => {
                setAddContentModalVisibility(false)
            }}
            okText="Submit"
        >
            {loading ? (
                <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
                <Form form={form} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
                    <Form.Item label="Question" name="question" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Answers" name="answers" rules={[{ required: true }]}>
                        <Form.List name="answers">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item {...restField} name={name} rules={[{ required: true, message: 'Missing first name' }]}>
                                                <Input
                                                    onChange={e => {
                                                        let myObject = { ...takenAnswers }
                                                        myObject[key] = e.target.value
                                                        setTakenAnswers(myObject)
                                                    }}
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined
                                                onClick={() => {
                                                    let myObject = { ...takenAnswers }
                                                    remove(name)
                                                    delete myObject[key]
                                                    setTakenAnswers(myObject)
                                                }}
                                            />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item label="Correct Answer Index" name="correctAnswerIndex" rules={[{ required: true }]}>
                        <Select placeholder="Select Correct Answer" allowClear>
                            <>
                                {Object.keys(takenAnswers)?.map((item, index) => (
                                    <Option value={index}>{takenAnswers[item]}</Option>
                                ))}
                            </>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Points" name="points">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Duration" name="duration">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="image" label="Image">
                        <Upload listType="picture" fileList={fileList} {...props}>
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}
