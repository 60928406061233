import {
  Button,
  Divider,
  Form,
  PageHeader,
  Pagination,
  Table,
  message,
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { exportOrders, listOrders } from "./network";
import { useNavigate } from "react-router-dom";
import { Filters } from "./components/Filters";
import { columns } from "./consts";
import { Order, OrderSearchBody } from "./type";

export default function OrderManager() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState<OrderSearchBody>()

  const [activeFilters, setActiveFilters] = useState({
    from: 0,
    size: 25,
    sortBy: {
      field: "createdAt",
      order: "desc",
    },
  });

  useEffect(() => {
    search(activeFilters.from);
  }, [activeFilters]);

  const submit = () => {
    search(0);
    setCurrentPage(1);
  };

  const search = async (from: number) => {
    setLoading(true)
    try {
      const values = form.getFieldsValue();
      const { segment, orderStatus, dateRange, ...rest } = values;
      const body: OrderSearchBody = {
        query: {
          from: from,
          size: activeFilters.size,
          sortBy: activeFilters.sortBy,
          dateRange: values?.dateRange
            ? {
                min:
                  values?.dateRange[0].startOf("day").valueOf().toString() ||
                  undefined,
                max:
                  values?.dateRange[1].endOf("day").valueOf().toString() ||
                  undefined,
              }
            : undefined,
          ...rest,
        },
      };
      if (values) {
        if (values?.segment || values?.orderStatus) {
          body.query.filters = [];
          if (values?.segment) {
            body.query.filters.push({
              filterId: "segment",
              filterValues: values.segment,
            });
          }
          if (values?.orderStatus) {
            body.query.filters.push({
              filterId: "orderStatus",
              filterValues: values.orderStatus,
            });
          }
        }
      }
      setSelectedFilters(body)
  
      const response = await listOrders(body);
  
      if (response && response?.status) {
        setOrderData(response?.data?.data);
        setTotalCount(response?.data?.totalCount);
        setLoading(false);
      } else {
        message.error("Bir hata oluştu!");
        setLoading(false);
      }
    } catch (error:any) {
      message.error(error?.response?.data || "Bir hata oluştu!")
    } finally{
      setLoading(false)
    }
  };

  const exportOrdersReq = async () => {
    setExportLoading(true)
    try {
      if(selectedFilters) {
        const body = selectedFilters
        delete body.query.from
        delete body.query.size
        const response = await exportOrders(body);
        
        if (response && response?.status) {
          window.open(response?.data?.signedUrl)
          setLoading(false);
        } else {
          message.error("Bir hata oluştu!");
          setLoading(false);
        }
      }
     
    } catch (error:any) {
      message.error(error?.response?.data || "Bir hata oluştu!")
    } finally {
      setExportLoading(false)
    }
  };

  const resetHandler = () => {
    form.resetFields();
    setCurrentPage(1);
    setActiveFilters({ ...activeFilters, from: 0 });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageHeader title="Order Manager" />
        <Button loading={exportLoading} type="primary" icon={<DownloadOutlined />} onClick={exportOrdersReq}>
          Export Order
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        <div>Filtreler</div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            type="primary"
            onClick={() => form.submit()}
            icon={<SearchOutlined />}
          >
            Ara
          </Button>
          <Button onClick={resetHandler}>Temizle</Button>
        </div>
      </div>
      <Filters form={form} submit={submit} />

      <Divider>Orders</Divider>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Table
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={orderData}
          onRow={(record: Order) => {
            return {
              onClick: (event) => {
                navigate(`${record?.orderId}`);
              },
            };
          }}
        />
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          pageSize={25}
          showSizeChanger={false}
          total={totalCount}
          style={{ alignSelf: "flex-end" }}
          onChange={(page) => {
            setCurrentPage(page);
            setActiveFilters({
              ...activeFilters,
              from: page === 1 ? 0 : (page - 1) * 25,
            });
          }}
        />
      </div>
    </>
  );
}
