import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import {
  GetContentCloudObject,
  GetProductCloudObject,
  GetManagerCloudObject,
  GetUserCloudObject,
} from "../utils/RBS";

import SignUp from "./Authentication/SignUp";
import SignIn from "./Authentication/SignIn";

import ContentManager from "./ContentManager";
import Content from "./ContentManager/Content";
import Trainings from "./ContentManager/sections/Training";
import Surveys from "./ContentManager/sections/Surveys";
import DidYouKnow from "./ContentManager/sections/DidYouKnow";

import ProductManager from "./ProductManager";
import UserManager from "./UserManager";
import DealerManager from "./DealerManager";
import UserSettings from "./UserSettings";
import BannerManager from "./BannerManager";
import Reports from "./Reports";

import App from "../App";
import OrderManager from "./OrderManager";
import OrderDetail from "./OrderManager/sections/OrderDetail";

export default function Router() {
  // useEffect(() => {
  //   GetContentCloudObject()
  //   GetProductCloudObject()
  //   GetUserCloudObject()
  // }, [])

  const isLoggedIn = useAppSelector((state) => state.authenticator.isLoggedIn);

  function PrivateRoute({ children }: any) {
    const auth = isLoggedIn;
    return auth ? children : <Navigate to="/login" />;
  }

  function PrivateRouteLogin({ children }: any) {
    const auth = !isLoggedIn;
    return auth ? children : <Navigate to="/" />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* AUTHENTICATION */}
        <Route
          path="/login"
          element={
            <PrivateRouteLogin>
              <SignIn />
            </PrivateRouteLogin>
          }
        />

        {/* APP */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          }
        >
          {/* <Route path="/" element={<Main />} /> */}
          <Route path="content-manager" element={<ContentManager />}>
            <Route path="training">
              <Route index element={<Trainings />} />
              <Route path=":content" element={<Content />} />
            </Route>
            <Route path="surveys">
              <Route index element={<Surveys />} />
              <Route path=":content" element={<Content />} />
            </Route>
            <Route path="didyouknow">
              <Route index element={<DidYouKnow />} />
              <Route path=":content" element={<Content />} />
            </Route>
          </Route>
          <Route path="product-manager" element={<ProductManager />} />
          <Route path="order-manager">
            <Route index element={<OrderManager />} />
            <Route path=":orderId" element={<OrderDetail />} />
          </Route>
          <Route path="user-manager" element={<UserManager />} />
          <Route path="banner-manager" element={<BannerManager />} />
          <Route path="dealer-manager" element={<DealerManager />} />
          <Route path="user-settings" element={<UserSettings />} />
          <Route path="reports" element={<Reports />} />
        </Route>

        {/* THERE IS NOTHING HERE */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
