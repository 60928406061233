import { ContentCloudObj, rio } from '../../utils/RBS'

export const getTrainingsNetwork = async () => {
    const response: any = await ContentCloudObj.call({ method: 'getTrainings' })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const upsertTrainingNetwork = async (body: any) => {
    const response: any = await ContentCloudObj.call({ method: 'upsertTraining', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const deleteTrainingNetwork = async (id: string) => {
    const response: any = await ContentCloudObj.call({ method: 'deleteTraining', body: { id: id } })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const upsertSurveyNetwork = async (body: any) => {
    const response: any = await ContentCloudObj.call({ method: 'upsertSurvey', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getSurveysNetwork = async () => {
    const response: any = await ContentCloudObj.call({ method: 'getSurveys' })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getSurveysAnswersNetwork = async (surveyId: string) => {
    const response: any = await ContentCloudObj.call({ method: 'getAllAnswerSurvey', body: { surveyId } })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getDidYouKnowNetwork = async () => {
    const response: any = await ContentCloudObj.call({ method: 'getDidYouKnow' })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const upsertDidYouKnowNetwork = async (body: any) => {
    const response: any = await ContentCloudObj.call({ method: 'upsertDidYouKnow', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const deleteDidYouKnowNetwork = async (id: string) => {
    const response: any = await ContentCloudObj.call({ method: 'deleteDidYouKnow', body: { id } })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const deleteSurveyNetwork = async (id: string) => {
    const response: any = await ContentCloudObj.call({ method: 'deleteSurvey', body: { id: id } })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const setFileNetwork = async (body: any) => {
    const response: any = await ContentCloudObj.call({ method: 'setFile', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}
