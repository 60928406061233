import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Role = 'admin' | 'callcenter' | 'super_admin'

interface AuthState {
  isLoggedIn: boolean
  loading: boolean
  username: string
  role?: Role
}

const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  username: ''
}

export const authSlice = createSlice({
  name: 'contentManager',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.username = action.payload
    },
    setRole: (state, action: PayloadAction<Role>) => {
      state.role = action.payload
    },
  },
  extraReducers: {
  },
})

export const { setLoggedIn, setLoading, setUserName, setRole } = authSlice.actions

export default authSlice.reducer