import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Skeleton, Input } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { deleteSurvey, getSurveys } from '../../../redux/content-manager'
import ContentManagerCard from '../../../components/ContentManagerCard'
import { Content } from '../types'

import { AddSurvey } from '../components/addContentModal'
import { SurveyResult } from '../components/surveyResult'

const { Search } = Input

export default function Surveys() {
    const dispatch = useAppDispatch()
    const [contents, setContents] = useState<Content[]>()
    const loading = useAppSelector(state => state.contentManager.loading)
    const surveys = useAppSelector(state => state.contentManager.surveys)

    const [search, setSearchText] = useState<string>()
    const [addContentModalVisibility, setAddContentModalVisibility] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<Content>({} as Content)
    const [selectedSurvey, setSurveyResultItem] = useState<Content | null>(null)

    useEffect(() => {
        dispatch(getSurveys())
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search) {
                let filteredContent = surveys.data.filter((item: any) => {
                    return item.title.toLowerCase().includes(search)
                })
                setContents(filteredContent)
            } else {
                setContents(surveys.data)
            }
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [search, surveys])

    const handleConfirm = (value: Content) => {
        dispatch(deleteSurvey(value.id)).then(() => dispatch(getSurveys()))
    }

    return (
        <div>
            {loading ? (
                <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
                <>
                    <Row justify="end" style={{ marginLeft: 16 }}>
                        <Search
                            placeholder="Search Traning"
                            style={{ width: 300, marginRight: 3 }}
                            onChange={(e: any) => {
                                setSearchText(e.target.value)
                            }}
                        />
                        <Button
                            onClick={() => {
                                setSelectedItem({} as Content)
                                setAddContentModalVisibility(true)
                            }}
                            type="dashed"
                        >
                            Add New Survey
                        </Button>
                    </Row>
                    <Row style={{ padding: 16, width: '100%' }} gutter={[8, 24]}>
                        {contents &&
                            contents.map((item: Content) => {
                                return (
                                    <Col className="gutter-row">
                                        <ContentManagerCard
                                            item={item}
                                            handleConfirm={handleConfirm}
                                            setAddContentModalVisibility={setAddContentModalVisibility}
                                            setSelectedItem={setSelectedItem}
                                            setSurveyResultItem={setSurveyResultItem}
                                        />
                                    </Col>
                                )
                            })}
                    </Row>
                </>
            )}
            <AddSurvey addContentModalVisibility={addContentModalVisibility} setAddContentModalVisibility={setAddContentModalVisibility} loading={loading} data={selectedItem} />

            <SurveyResult survey={selectedSurvey} visible={!!selectedSurvey} onClose={() => setSurveyResultItem(null)} />
        </div>
    )
}
