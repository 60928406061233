import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cancelOrder, getOrder } from "../network";
import {
  Button,
  Descriptions,
  Divider,
  Popconfirm,
  Table,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { Order } from "../type";
import moment from "moment";
import { orderDetailColumns } from "../consts";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/Loading";

export default function OrderDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.orderId;
  const [data, setData] = useState<Order>();
  const userRole = useAppSelector((state) => state?.authenticator?.role);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderReq();
  }, []);

  const getOrderReq = async () => {
    setLoading(true);
    try {
      if (orderId) {
        const response = await getOrder(orderId);
        if (response?.status) {
          setData(response.data);
        }
      }
    } catch (error: any) {
      message.error(error?.response?.data || "Bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const cancelOrderReq = async () => {
    setLoading(true);
    try {
      if (orderId) {
        const response = await cancelOrder(orderId);
        if (response?.status) {
          getOrderReq();
          message.success("Sipariş iptal edildi!");
        } else {
          message.warning("Sipariş iptal edilemedi!");
        }
      }
    } catch (error: any) {
      message.error(error?.response?.data || "Bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{width: "100%", height: "100%"}}> 
      {loading ? (
        <div style={{height: "100%",display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Loading />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          {userRole === "super_admin" &&
          <div style={{ textAlign: "right" }}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={cancelOrderReq}
              disabled={data?.orderStatus === "CANCELLED"}
            >
              <Button
                danger
                style={{ width: 150, borderRadius: 5 }}
                disabled={data?.orderStatus === "CANCELLED"}
                loading={loading}
              >
                Cancel Order
              </Button>
            </Popconfirm>
          </div>
          }
          <Descriptions
            labelStyle={{ fontWeight: 600 }}
            layout="vertical"
            title={
              <>
                <ArrowLeftOutlined
                  onClick={() => {
                    navigate("/order-manager");
                  }}
                  style={{ marginRight: "5px" }}
                />{" "}
                {"Order ID: " + data?.orderId}
              </>
            }
            bordered
            column={4}
          >
            <Descriptions.Item label="User ID">
              {data?.userId || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {data?.userName || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {data?.userPhoneNumber || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Segment">
              {data?.userSegment || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant ID">
              {data?.merchantId || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant Name">
              {data?.merchantName || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant Phone">
              {data?.merchantPhoneNumber || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant Email">
              {data?.merchantEmail || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant Province">
              {data?.merchantProvince || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant District">
              {data?.merchantDistrict || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm:ss") || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {moment(data?.updatedAt).format("DD/MM/YYYY HH:mm:ss") || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Order Status">
              {data?.orderStatus || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery At Store">
              {data?.deliveryAtStore ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <CloseCircleTwoTone twoToneColor="#eb2f96" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Status">
              {data?.paymentStatus || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Method">
              {data?.paymentMethod || "-"}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            labelStyle={{ fontWeight: 600 }}
            layout="vertical"
            title={"Cart"}
            bordered
            column={5}
          >
            <Descriptions.Item label="Product Count">
              {data?.cart?.productCount || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Price To Pay">
              {data?.cart?.priceToPay + " ₺" || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Total Price">
              {data?.cart?.totalPrice + " ₺" || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Total Price Without Point Discount">
              {data?.cart?.totalPriceWithoutPointDiscount + " ₺" || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Total Jotun Point">
              {data?.cart?.totalJotunPoint || "-"}
            </Descriptions.Item>
          </Descriptions>
          <Divider>Products</Divider>
          <Table columns={orderDetailColumns} dataSource={data?.cart?.items} />
        </div>
      )}
    </div>
  );
}
