import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Skeleton, Input } from 'antd';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { deleteDidYouKnow, deleteSurvey, getDidYouKnow, getSurveys } from '../../../redux/content-manager'
import ContentManagerCard from '../../../components/ContentManagerCard';
import { Content } from '../types'

import { AddDidYouKnow } from '../components/addContentModal';

const { Search } = Input;

export default function Surveys() {
  const dispatch = useAppDispatch()
  const [contents, setContents] = useState<Content[]>();
  const loading = useAppSelector(state => state.contentManager.loading)
  const didYouKnow = useAppSelector(state => state.contentManager.didYouKnow)

  const [search, setSearchText] = useState<string>();
  const [addContentModalVisibility, setAddContentModalVisibility] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Content>({} as Content);

  useEffect(() => {
    dispatch(getDidYouKnow())
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        let filteredContent = didYouKnow.data.filter((item: any) => {
          return item.question.toLowerCase().includes(search)
        })
        console.log(filteredContent);
        
        setContents(filteredContent)
      } else {
        console.log(didYouKnow.data);
        setContents(didYouKnow.data)
      }
    }, 400)
    return () => clearTimeout(delayDebounceFn)
  }, [search, didYouKnow]);

  const handleConfirm = (value: Content) => {
    dispatch(deleteDidYouKnow(value.id)).then(() => dispatch(getDidYouKnow()))
  }

  return <div>
    {
      loading
        ? <Skeleton active paragraph={{ rows: 4 }} />
        : <>
          <Row justify='end' style={{ marginLeft: 16 }} >
            <Search
              placeholder="Search Traning"
              style={{ width: 300, marginRight: 3 }}
              onChange={(e: any) => { setSearchText(e.target.value) }}
            />
            <Button onClick={() => {
              setSelectedItem({} as Content)
              setAddContentModalVisibility(true)
            }} type='dashed'>Add New Popup Quiz</Button>
          </Row>
          <Row style={{ padding: 16, width: '100%' }} gutter={[8, 24]}>
            {contents && contents.map((item: Content) => {
              return (
                <Col className="gutter-row" >
                  <ContentManagerCard
                    item={item}
                    handleConfirm={handleConfirm}
                    setAddContentModalVisibility={setAddContentModalVisibility}
                    setSelectedItem={setSelectedItem}
                  />
                </Col>
              )
            })}
          </Row>
        </>
    }
    <AddDidYouKnow
      addContentModalVisibility={addContentModalVisibility}
      setAddContentModalVisibility={setAddContentModalVisibility}
      loading={loading}
      data={selectedItem}
    />
  </div>;
}
