export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const capitilizer = (word: string) => {
  return word[0].toUpperCase() + word.substring(1)
}

export function bufferToBase64(buf: any) {
  var binstr = Array.prototype.map.call(buf, function (ch) {
    return String.fromCharCode(ch);
  }).join('');
  return btoa(binstr);
}

export const fileToArrayBuffer = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event: any) => {
    resolve(event.target.result)
  };
  reader.readAsArrayBuffer(file);
})

export const arrayOfObjectToObject = (data: any, key: string) => {
  return data.reduce(
    (a: any, v: any) => ({ ...a, [v[key]]: v }
    ), {})
}

export function dateToUnix(dateString: string) {
 const [year, month, day] = dateString.split('-').map(Number);
 const unixTime = new Date(year, month - 1, day).getTime();

 return unixTime;
}
