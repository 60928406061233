import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd';
import Product from './sections/Product';
import Price from './sections/Price';
import ColourAndReflex from './sections/ColourAndReflex'
import FeaturedColours from './sections/FeaturedColours'

const { TabPane } = Tabs;

export default function ContentManager() {
  function callback(key: any) {
  }

  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Product" key="1">
          <Product />
        </TabPane>
        <TabPane tab="Colour & Reflex" key="2">
          <ColourAndReflex />
        </TabPane>
        <TabPane tab="Featured Colours" key="3">
          <FeaturedColours />
        </TabPane>
        <TabPane tab="Prices" key="4">
          <Price />
        </TabPane>
      </Tabs>
    </div>
  )
}