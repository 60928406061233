import { Modal } from 'antd'
import { useEffect, useState } from 'react'
// @ts-ignore
import { exportToExcel } from 'react-json-to-excel'

import { getSurveysAnswersNetwork } from '../network'

export const SurveyResult = ({ survey, visible, onClose }: any) => {
    const [results, setResults] = useState<any>([])

    useEffect(() => {
        if (!survey) {
            setResults([])
            return
        }
        getSurveysAnswersNetwork(survey.id)
            .then(res => {
                const items = res.data.items.map((r: any) => r.data)
                setResults(
                    items.reduce((acc: any, item: any) => {
                        const question = item.question
                        if (!acc[question]) {
                            acc[question] = []
                        }
                        acc[question].push(item)
                        return acc
                    }, {})
                )
            })
            .then(err => {
                console.log(err)
            })
    }, [survey])

    if (!survey) return null

    return (
        <Modal
            style={{ borderRadius: 100 }}
            width={850}
            title="Survey Results"
            visible={visible}
            onCancel={() => onClose(false)}
            cancelText="Kapat"
            okText="Sonuçları İndir"
            onOk={() => {
                const resultsArray = Object.keys(results).flatMap((question: any) => {
                    const answers = results[question]
                    return answers.map((answer: any) => {
                        return {
                            soru: question,
                            cevap: answer.answer,
                            usta: answer.userName,
                            bayi: answer.merchantName,
                            '': '',
                            userId: answer.userId,
                            surveyId: answer.surveyId,
                        }
                    })
                })

                exportToExcel(resultsArray, survey.title)
            }}
        >
            {Object.keys(results).map((question: any) => {
                const answers = results[question]
                const groupedAnswers = answers.reduce((acc: any, item: any) => {
                    const answer = item.answer
                    if (!acc[answer]) {
                        acc[answer] = 0
                    }
                    acc[answer]++
                    return acc
                }, {})
                return (
                    <div style={{ marginBottom: 16 }}>
                        <h3>{question}</h3>
                        {Object.keys(groupedAnswers).map((answer: any) => {
                            return (
                                <div>
                                    {answer}: {groupedAnswers[answer]}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </Modal>
    )
}
