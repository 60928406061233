import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd"
import { useEffect, useState } from "react";
import { getCities, getDistricts } from "../network";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
  form: FormInstance;
  submit: () => void;
}

export const Filters = ({
  form,
  submit
}: Props) => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    getCitiesReq();
  }, []);

  const getCitiesReq = async () => {
    const citiesResponse: any = await getCities() || [];

    if (citiesResponse) {
      const citiesData = citiesResponse?.data?.map((city: any) => ({
        value: city,
        label: city,
      }));
      setCities(citiesData);
    }
  };

  const onSelectCity = async (value: any) => {
    form.setFieldsValue({ merchantDistrict: undefined });
    const districtsResponse: any = await getDistricts(value);
    if (districtsResponse) {
      const districtsData = districtsResponse?.data?.map((city: any) => ({
        value: city,
        label: city,
      }));
      setDistricts(districtsData);
    }
  };


return (
  <div>
        <Form
          form={form}
          name="basic"
          onFinish={submit}
          wrapperCol={{ span: 0 }}
          autoComplete="off"
        >
          <Row gutter={[16, 0]}>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="orderId">
                <Input placeholder="Order No" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="userId">
                <Input placeholder="User ID" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="userName">
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="msisdn">
                <Input placeholder="User Phone Number" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="merchantName">
                <Input placeholder="Merchant Name" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="merchantPhoneNumber">
                <Input placeholder="Merhant Phone Number" />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="dateRange">
                <RangePicker style={{width: "100%"}}/>
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="orderStatus">
                <Select placeholder="Order Status" mode="multiple" allowClear>
                  <Option value="PENDING">Pending</Option>
                  <Option value="DELIVERED">Delivered</Option>
                  <Option value="ORDERED">Ordered</Option>
                  <Option value="PREPARING">Preparing</Option>
                  <Option value="READY">Ready</Option>
                  <Option value="SHIPPEDOUT">Shipped Out</Option>
                  <Option value="CANCELLED">Cancelled</Option>
                  <Option value="RETURNED">Returned</Option>
                  <Option value="PAYMENT_FAILED">Payment Failed</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item name="segment">
                <Select placeholder="Segment" mode="multiple" allowClear>
                  <Option value="bronze">Bronze</Option>
                  <Option value="silver">Silver</Option>
                  <Option value="gold">Gold</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item
                name="merchantProvince"
                labelCol={{ span: 6, offset: 0 }}
              >
                <Select
                  placeholder='Merchant Province'
                  allowClear
                  options={cities}
                  onChange={(value: any) => onSelectCity(value)}
                />
              </Form.Item>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={4}>
              <Form.Item
                name="merchantDistrict"
                labelCol={{ span: 6, offset: 0 }}
              >
                <Select
                allowClear
                  placeholder="Merchant District"
                  options={districts}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
)
}