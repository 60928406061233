"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64Encode = exports.getRuntime = void 0;
function getRuntime() {
    if (typeof document != 'undefined')
        return 'web';
    if (typeof navigator != 'undefined' && navigator.product == 'ReactNative')
        return 'react-native';
    return 'node';
}
exports.getRuntime = getRuntime;
function base64Encode(str) {
    return Buffer.from(str).toString('base64');
}
exports.base64Encode = base64Encode;
