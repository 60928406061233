import React, { useState, useEffect } from 'react'
import { getUserProfile } from './network'
import { Skeleton, Input, Row, PageHeader, Button, Form, Descriptions, Result } from 'antd';


export default function UserManager() {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<any>()
  const [userData, setUserData] = useState<any>()

  const onFinish = async (values: any) => {
    setErrorMessage(null)
    setUserData(null)
    setLoading(true)
    try {
      let user = await getUserProfile(values.msisdn)
      if (user.status) {
        setUserData(user.data)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error.response.data)
    }
  }

  const camelCaseToTitleCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return (
    <div>
      <PageHeader title="User Settings" />
      <Form onFinish={onFinish}>
        <Row>
          <Form.Item name="msisdn" label="Search User" style={{ marginLeft: 24, width: 300 }}>
            <Input placeholder="Phone Number" />
          </Form.Item>
          <Form.Item style={{ marginLeft: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {(userData && !loading) ?
        <Descriptions bordered style={{ marginLeft: 24, marginTop: 20 }}>
          {Object.keys(userData).map(item => {
            return <Descriptions.Item span={1} label={camelCaseToTitleCase(item)} > {userData[item]}</Descriptions.Item>
          })}
        </Descriptions> :
        loading && <Skeleton active />
      }
      {errorMessage && <>
        <Result
          status="warning"
          title={errorMessage.message}
        />
      </>}
    </div >
  )
}