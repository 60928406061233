import React, { useEffect, useState } from 'react'
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom'
import logo from './assets/jotun-logo.png'
import { Layout, Menu, Button, Row, Card } from 'antd'
import { useAppSelector, useAppDispatch } from './redux/hooks'
import { setLoggedIn, setLoading } from './redux/authenticator'
import { GetContentCloudObject, GetProductCloudObject, GetManagerCloudObject, GetUserCloudObject, ManagerCloudObj, GetMerchantAuthCloudObject, GetBannerCloudObject, GetOrderCloudObject, GetPlacesCloudObject } from './utils/RBS'
import { LineChartOutlined, SnippetsOutlined, TableOutlined, UsergroupAddOutlined, SettingOutlined, FileImageOutlined } from '@ant-design/icons'

import './App.css'
import { rio } from './utils/RBS'

const { Header, Sider, Content } = Layout

function App() {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [collapsed, setCollapse] = useState<boolean>(false)
    const [selectedKey, setSelectedKey] = useState<any>()
    const [currency, setCurrency] = useState<any>()
    const [appReady, setAppReady] = useState<boolean>(false)

    const username = useAppSelector(state => state.authenticator.username)

    useEffect(() => {
        if (location.pathname.split('/')[1] !== '') {
            setSelectedKey(location.pathname.split('/')[1])
        } else {
            setSelectedKey('/')
        }
    }, [location])

    useEffect(() => {
        rio.authStatus.subscribe((event: any) => {
            if (event.authStatus === 'SIGNED_IN') {
                dispatch(setLoggedIn(true))
            } else {
                dispatch(setLoggedIn(false))
                navigate('/login')
            }
        })

        Promise.all([GetContentCloudObject(), GetProductCloudObject(), GetUserCloudObject(), GetManagerCloudObject(), GetMerchantAuthCloudObject(), GetBannerCloudObject(), GetOrderCloudObject(), GetPlacesCloudObject()]).then(() => {
            setAppReady(true)
            getCurreny()
        })
    }, [])

    const getCurreny = async () => {
        const response: any = await ManagerCloudObj.call({ method: 'getCurrencies' })
        if (response.status === 200) {
            setCurrency(response.data)
        }
    }

    const handleMenuClick = (e: any) => {
        setSelectedKey(e.key)
    }

    const logout = () => {
        rio.signOut()
        dispatch(setLoggedIn(false))
        dispatch(setLoading(false))
        navigate('/login')
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider theme="light" collapsible collapsed={collapsed} onCollapse={() => setCollapse(!collapsed)}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img className="logo" src={logo} />
                </div>
                <Menu defaultOpenKeys={['/content-manager']} selectedKeys={[selectedKey]} onClick={handleMenuClick}>
                    <Menu.Item key="content-manager" icon={<SnippetsOutlined />}>
                        <Link to="/content-manager/training">Content Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="product-manager" icon={<TableOutlined />}>
                        <Link to="/product-manager">Product Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="order-manager" icon={<TableOutlined />}>
                        <Link to="/order-manager">Order Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="banner-manager" icon={<FileImageOutlined />}>
                        <Link to="/banner-manager">Banner Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="user-manager" icon={<UsergroupAddOutlined />}>
                        <Link to="/user-manager">User Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="dealer-manager" icon={<UsergroupAddOutlined />}>
                        <Link to="/dealer-manager">Dealer Manager</Link>
                    </Menu.Item>
                    <Menu.Item key="user-settings" icon={<SettingOutlined />}>
                        <Link to="/user-settings">User Settings</Link>
                    </Menu.Item>
                    <Menu.Item key="reports" icon={<LineChartOutlined />}>
                        <Link to="/reports">Reports</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Row justify="space-between" style={{ height: '100%', marginRight: 12 }}>
                        <p style={{ marginLeft: 12, color: '#595959' }}>USD {currency?.usd?.toFixed(2)}₺</p>
                        <div style={{ color: '#595959' }}>
                            {username}
                            <Button danger style={{ marginLeft: 12, borderRadius: 5 }} onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </Row>
                </Header>
                {appReady ? (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                        }}
                    >
                        <Outlet />
                    </Content>
                ) : null}
                {/* <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content> */}
            </Layout>
        </Layout>
    )
}

export default App
