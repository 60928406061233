import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Typography, Divider, Row, Tabs } from 'antd'

const { Title } = Typography
const { TabPane } = Tabs

export default function ContentManager() {
    const location = useLocation()
    const navigate = useNavigate()

    const tabOnChange = (key: any) => {
        navigate(`/content-manager/${key}`)
    }

    return (
        <div>
            <Row justify="space-between">
                <Title level={3}>Content Manager</Title>
            </Row>
            <Divider style={{ margin: 5 }} />
            <div style={{ margin: 10 }}>
                <Tabs onChange={tabOnChange} type="card" defaultActiveKey={location.pathname.split('/')[2] || '1'}>
                    <TabPane tab="Trainings" key="training" />
                    <TabPane tab="Surveys" key="surveys" />
                    {/* <TabPane tab="Popup Quiz" key="didyouknow" /> */}
                </Tabs>
            </div>
            <Outlet />
        </div>
    )
}
