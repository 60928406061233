import { ProductManagerCloudObj, rio } from '../../utils/RBS'

export const importProductNetwork = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'importProducts', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const importPricesNetwork = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'importPrices', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const importColorNetwork = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'importColors', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const triggerExportNetwork = async (attribute: 'color' | 'price' | 'product' = 'product') => {
    const response: any = await ProductManagerCloudObj.call({ method: 'triggerExport', body: { attribute } })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getColorList = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'getColorList', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const getFeaturedColorList = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'getFeaturedColorList', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}

export const setFeaturedColorList = async (body: any) => {
    const response: any = await ProductManagerCloudObj.call({ method: 'setFeaturedColorList', body: body })
    if (response.status.toString().includes('2')) {
        return { status: true, data: response.data }
    } else {
        return { status: false }
    }
}
