import { rio } from '../../utils/RBS'

export const getUserProfile = async (msisdn: string) => {
  let User = await rio.getCloudObject({
    classId: 'User',
    key: {
      name: 'msisdn',
      value: msisdn,
    },
  })

  const response: any = await User.call({ method: 'getProfile' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}
