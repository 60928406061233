import { useState } from "react";
import { getUsers, getMerchants, getRewards } from "./network";
import { Input, Row, Button, Form, Tabs, message } from "antd";
// @ts-ignore
import { exportToExcel } from "react-json-to-excel";
import { dateToUnix } from "../../utils/functions/general";

const { TabPane } = Tabs;

export default function UserManager() {
  const [loading, setLoading] = useState<boolean>(false);
  function callback(key: any) {}

  const onFinishUsers = async (values: any) => {
    setLoading(true);
    try {
      let user = await getUsers(values.startDate, values.endDate);
      if (user.status) {
        const data = user.data.users.map((r: any) => ({
          ad: r.firstName,
          soyad: r.lastName,
          il: r.province,
          ilce: r.district,
          telefon: r.msisdn ?? r.phoneNumber,
          bayi: r.merchantName,
          durum: r.status,
          "sms izni": r.settings?.notifications?.sms ? "Evet" : "Hayır",
          "telefon izni": r.settings?.notifications?.phoneCall
            ? "Evet"
            : "Hayır",
          "eklenme tarihi": r.createdAt,
          "guncellenme tarihi": r.updatedAt,
          id: r.id,
          "toplam siparis tutari": r.allOrderTotalPrice,
          "kazanilan toplam jotun puan": r.earnedJotunPoints,
          "kullabilabilir jotun puan": r.currentSpendableJotunPoint,
          "harcanilan puan": r.spentPoint,
        }));
        if (data.length > 0) {
          exportToExcel(data, "ustalar");
        } else {
          alert("Filtere uygun usta bulunamadı.");
        }
      }
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const onFinishMerchants = async (values: any) => {
    setLoading(true);
    try {
      let user = await getMerchants(values.startDate, values.endDate);
      if (user.status) {
        const data = user.data.merchants.map((r: any) => ({
          ad: r.name,
          email: r.email,
          telefon: r.msisdn ?? r.phoneNumber,
          paynetAgentId: r.paynetAgentId,
          location: Object.values(r.location).join(","),
          sorumlu: r.dealerName,
          adres: r.address,
          "eklenme tarihi": r.createdAt,
          "guncellenme tarihi": r.updatedAt,
          id: r.id,
          "toplam siparis tutari": r.allOrderTotalPrice,
        }));
        exportToExcel(data, "merchants");
      }
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const onFinishRewards = async (values: any) => {
    setLoading(true);
    try {
      const minDate = dateToUnix(values.minDate)
      const maxDate = dateToUnix(values.maxDate)
      const response = await getRewards(minDate, maxDate);
      if (response.status) {
        window.open(response?.data?.signedUrl)
      }
    } catch (error: any) {
      message.error(error?.response?.data || "Bir hata oluştu!")
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Users" key="1">
          <Form onFinish={onFinishUsers}>
            <Row>
              <Form.Item
                name="startDate"
                label="Start Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item style={{ marginLeft: 8 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Get
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="Dealers" key="2">
          <Form onFinish={onFinishMerchants}>
            <Row>
              <Form.Item
                name="startDate"
                label="Start Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item style={{ marginLeft: 8 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Get
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="Rewards" key="3">
          <Form onFinish={onFinishRewards}>
            <Row>
              <Form.Item
                name="minDate"
                label="Start Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item
                name="maxDate"
                label="End Date"
                style={{ marginLeft: 24, width: 300 }}
              >
                <Input type="date" required />
              </Form.Item>
              <Form.Item style={{ marginLeft: 8 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Get
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
}
