import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setLoggedIn, setUserName, setLoading, setRole } from '../../redux/authenticator'
import { Form, Input, Button, Row, Card, message } from 'antd'
import { Link, useNavigate } from "react-router-dom";
import { rio } from '../../utils/RBS';
import Loading from '../../components/Loading';
import JotunLogo from '../../assets/jotun-logo.png'

export default function Authentication() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const loading = useAppSelector(state => state.authenticator.loading)

  useEffect(() => {
    rio.signOut()
    dispatch(setLoading(false))
  }, [])

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoading(true))
      const cloudObject = await rio.getCloudObject({
        classId: 'AdminAuth',
        key: {
          name: "email",
          value: values.email
        },
      })

      const res: any = await cloudObject.call({
        method: 'signIn',
        body: {
          password: values.password
        }
      })

      if (res.data.customToken) {
        const request = await rio.authenticateWithCustomToken(res.data.customToken)
        if (request) {
          const user = await rio.getCurrentUser()
          dispatch(setLoggedIn(true))
          navigate('/')
          dispatch(setUserName(values.email))
          dispatch(setRole(user?.identity as any))
          dispatch(setLoading(false))
        }
      }
    } catch (error) {
      message.error('Wrong Credentials')
      dispatch(setLoading(false))
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify='center'>
      <Row justify='center' style={{ width: '100%', marginTop: 150 }}>
        <img width={300} src={JotunLogo} />
      </Row>
      <Card title="Login" style={{ width: 600, marginTop: 50, borderRadius: 15 }}>
        {loading ?
          <Row justify='center'>
            <Loading />
          </Row>
          :
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            labelCol={{ span: 4 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              style={{ marginTop: 20 }}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Row justify='center'>
              {/* <Form.Item>
                <Link to="/signup"> Sign Up</Link>
              </Form.Item> */}
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}>
                <Button type="primary" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </Row>

          </Form>
        }
      </Card>
    </Row>
  )
}