import React, { useState, useEffect } from "react";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { PageHeader, Space, Table, Button, Popconfirm, message, Image } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { AddEditBannerModal } from "./components/AddEditBannerModal";
import { CSS } from './utils';
import { Banner } from './type';
import { getBannerList, deleteBanner } from "./network";
import moment from "moment";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    //@ts-ignore
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function BannerManager() {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [dataSource, setDataSource] = useState<Banner[]>([]);

  const hideModal = () => setModalVisible(false);

  useEffect(() => {
    if (!modalVisible) {
      setModalData(null);
      getBanners();
    }
  }, [modalVisible]);

  const getBanners = async () => {
    setLoading(true)
    let response = await getBannerList()
    if (response?.data) {
      setDataSource(response.data.banners);
      setLoading(false)
    }
  };

  const editBannerHandler = (record: any) => {
    setModalData(record);
    setModalVisible(true);
  };

  const deleteBannerHandler = async (record: any) => {
    setLoading(true);
    const response = await deleteBanner(record.id);
    if (response.data) {
      getBanners();
    } else {
      message.error('Bir Hata Oldu')
      setLoading(false);
    }
  };

  const truncate = (source: string, size: number) => {
    return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
  }

  const columns = [
    {
      key: 'sort',
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: undefined, row: Banner) => (
        <Image src={row.imageUrl} width={75} height={75} />
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value: string) => (
        <span>{truncate(value, 140)}</span>
      )
    },
    {
      title: "Publish At",
      dataIndex: "publishAt",
      key: "publishAt",
      render: (value: string) => (
        <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>
      )
    },
    {
      title: "Expire At",
      dataIndex: "expireAt",
      key: "expireAt",
      render: (value: string) => (
        <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>
      )
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (value: string) => (
        <span style={{color: value ? 'green' : 'red'}}>{value ? 'Active' : 'Inactive'}</span>
      )
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (_: undefined, row: Banner) => (
        <Space size="middle">
          <Button
            onClick={() => editBannerHandler(row)}
            style={{ color: "green", borderColor: "green" }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteBannerHandler(row)}
          >
            <Button danger ghost>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const addNewBannerHandler = () => {
    setModalData(null);
    setModalVisible(true);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageHeader title="Banner Manager" />
        <Button onClick={addNewBannerHandler} type="dashed">
          Add New Banner
        </Button>
      </div>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            
          />
        </SortableContext>
      </DndContext>
      <AddEditBannerModal
        visible={modalVisible}
        hideModal={hideModal}
        loading={loading}
        data={modalData}
      />
    </div>
  );
}
