import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Skeleton, Popconfirm, Tabs, Form, Modal, Input, Divider, Space, InputNumber } from 'antd';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { getTrainings, deleteTraining } from '../../../redux/content-manager'
import ContentManagerCard from '../../../components/ContentManagerCard';
import { Content } from '../types'

import { AddTraining } from '../components/addContentModal';

const { Search } = Input;

export default function Surveys() {
  const dispatch = useAppDispatch()
  const [contents, setContents] = useState<Content[]>();
  const loading = useAppSelector(state => state.contentManager.loading)
  const trainings = useAppSelector(state => state.contentManager.training)

  const [search, setSearchText] = useState<string>();
  const [addContentModalVisibility, setAddContentModalVisibility] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Content>({} as Content);

  useEffect(() => {
    dispatch(getTrainings())
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        let filteredContent = trainings.data.filter((item: any) => {
          return item.title.toLowerCase().includes(search)
        })
        setContents(filteredContent)
      } else {
        setContents(trainings.data)
      }
    }, 400)
    return () => clearTimeout(delayDebounceFn)
  }, [search, trainings]);


  const handleConfirm = (value: Content) => {
    dispatch(deleteTraining(value.id)).then(() => dispatch(getTrainings()))
  }

  return <div>{
    loading
      ? <Skeleton active paragraph={{ rows: 4 }} />
      : <>
        <Row justify='end' style={{ marginLeft: 16 }} >
          <Search
            placeholder="Search Traning"
            style={{ width: 300, marginRight: 3 }}
            onChange={(e: any) => { setSearchText(e.target.value) }}
          />
          <Button onClick={() => {
            setSelectedItem({} as Content)
            setAddContentModalVisibility(true)
          }} type='dashed'>Add New Training</Button>
        </Row>
        <Row style={{ padding: 16, width: '100%' }} gutter={[8, 24]}>
          {contents && contents.map((item: Content) => {
            return (
              <Col className="gutter-row" >
                <ContentManagerCard
                  item={item}
                  handleConfirm={handleConfirm}
                  setAddContentModalVisibility={setAddContentModalVisibility}
                  setSelectedItem={setSelectedItem}
                />
              </Col>
            )
          })}
        </Row>
      </>
  }
    <AddTraining
      loading={loading}
      setAddContentModalVisibility={setAddContentModalVisibility}
      addContentModalVisibility={addContentModalVisibility}
      data={selectedItem}
    />
  </div>;
}
