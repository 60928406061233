import { Row, Popconfirm } from 'antd';

export const columnGenerator = (
  data: any,
  change: {
    changeable?: boolean,
    editFunction?: any
  },
  drag: {
    draggable?: boolean,
    DragHandle?: any
  }
) => {

  // set keys uniquely with object key values,
  // search all data for getting different columns if exsist
  let keys: any = []
  data.forEach((item: any) => {
    Object.keys(item).forEach(key => {
      let isKeyFounded = keys.find((item: any) => item.key === key)
      if (!isKeyFounded && key !== 'index') {
        if (Array.isArray(item[key])) { // if value is array
          keys.push({ key: key, array: true })
        } else {
          keys.push({ key: key, array: false })
        }
      }
    })
  })

  // Capitilize table column titles
  let columns: any = keys?.map((item: any) => {
    let caplitilizeTitle = item.key[0].toUpperCase() + item.key.substring(1)
    if (item.array) {
      return {
        title: caplitilizeTitle,
        dataIndex: item.key,
        render: (items: any) => (
          items?.map((myvalue: any, index: number) => {
            if (index + 1 === items.length) {
              return (<> {myvalue}</>)
            }
            return (<> {myvalue},</>)
          })
        )
      }
    }
    if (item.key === 'image') {
      return {
        title: caplitilizeTitle,
        dataIndex: item.key,
        render: (items: any) => (
          <img width={50} src={items} alt="" />
        )
      }
    }
    if (item.array) {
      return {
        title: caplitilizeTitle,
        dataIndex: item.key,
        render: (items: any) => (
          items?.map((myvalue: any, index: number) => {
            if (index + 1 === items.length) {
              return (<> {myvalue}</>)
            }
            return (<> {myvalue},</>)
          })
        )
      }
    }
    return {
      title: caplitilizeTitle,
      dataIndex: item.key
    }
  })


  // add action column for edit, delete
  if (change.changeable) {
    columns = [...columns, {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (record: any) => (
        <Row justify='center'>
          <a onClick={() => { change.editFunction.setSelectedData(record) }}>Edit </a>
          <Popconfirm
            title={`Are you sure to delete this row?`}
            onConfirm={() => {
              let newData = [...data]
              change.editFunction.deleteFunction(record, newData)
            }}
            okText="Yes"
            cancelText="No"
          >
            <a style={{ color: 'red', marginLeft: 20 }}>Delete</a>
          </Popconfirm>
        </Row>
      ),
    }]
  }

  // If it is draggble, add the
  // first column of table
  if (drag.draggable) {
    columns = [{
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <drag.DragHandle />,
    }, ...columns]
  }

  console.log(columns);

  return columns
}