// @ts-ignore
import { exportToExcel } from 'react-json-to-excel'
import { Button, Divider, Form, Input, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { rio } from '../../utils/RBS'

const fieldsNames: any = {
    name: 'Bayii adı',
    latlng: 'Koordinat',
    paynetAgentId: 'Paynet Code',
    address: 'Addres',
    dealerName: 'Sorumlu Adı',
    emailAddress: 'Sorumlu Email',
    msisdn: 'Sorumlu Telefon',
}

const fieldMessages: any = {
    name: 'Bayii adı kontrol ediniz',
    latlng: 'Koordinatları kontrol ediniz. \nÖrnek: 41.008240,28.978359',
    paynetAgentId: 'Paynet Bayi kodunu kontrol ediniz, \nÖrnek: 2XXXX',
    address: 'Adresi kontrol ediniz',
    dealerName: 'Sorumlu adını kontrol ediniz',
    emailAddress: 'Geçerli bir email adresi giriniz. \nİçerisinde Türkçe karakter bulunmamalıdır. \nÖrnek:bayi@jotun.com',
    msisdn: 'Telefon numarasını 12 haneli ve başında 90 olacak şekilde giriniz. \nÖrnek: 905XXXXXXXXX',
}

export const BulkDealerManager = () => {
    const [form] = Form.useForm()

    const onFinish = async (values: any) => {
        try {
            const body = values.items.map((r: any, i: number) => {
                if (r.latlng.split(',').length !== 2) {
                    throw new Error(`__Satır: ${i + 1}\n${fieldMessages.latlng}`)
                }
                const lat = r.latlng.split(',')[0].trim()
                const lng = r.latlng.split(',')[1].trim()
                delete r.latlng
                // map over and trim all values
                const data = Object.keys(r).reduce((acc: any, key: any) => {
                    acc[key] = r[key]?.trim()
                    return acc
                }, {})
                return {
                    ...data,
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                }
            })

            const cos = await rio.getCloudObject({
                classId: 'Merchant',
                instanceId: 'default',
                useLocal: true,
            })
            const r = await cos.call({
                method: 'manuelMerchantAdd',
                body,
            })

            exportToExcel(r.data, 'dealer-list')
            alert('Bayi listesi başarıyla oluşturuldu. İndiriliyor...')
        } catch (error: any) {
            console.log(error)

            // check status code
            if (error.response?.data?._friendlyMessage) {
                alert(error.response.data._friendlyMessage.message)
            } else if (error.response?.data?.code === 'VALIDATION') {
                const data = error.response.data
                let message = 'Aşağıdaki hataları kontrol ediniz:'
                if (Array.isArray(data.issues)) {
                    message += data.issues
                        .map((r: any) => {
                            // /0/msisdn
                            const row = parseInt(r.instancePath.split('/')[1])
                            const field = r.instancePath.split('/')[2]
                            if (field === 'lat' || field === 'lng') {
                                return `\nSatır: ${row + 1} - ${fieldMessages[field]}`
                            }
                            return `\nSatır: ${row + 1} - ${fieldMessages[field]}`
                        })
                        .join('\n')
                }
                alert(message)
            } else if (error.message?.startsWith('__')) {
                alert(error.message.replace('__', ''))
            } else {
                alert('Tüm alanları kontrol edin ve doğru girdinizden emin olunuz.')
            }
        }
    }

    return (
        <div>
            <Form form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <div
                                    key={field.key}
                                    style={{
                                        padding: '16px',
                                        marginBottom: '8px',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    <Divider />
                                    <Space align="baseline">
                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.items !== curValues.items}>
                                            {() => (
                                                <Form.Item {...field} label="Bayii adı" name={[field.name, 'name']} rules={[{ required: true, message: 'required' }]}>
                                                    <Input />
                                                </Form.Item>
                                            )}
                                        </Form.Item>

                                        <Form.Item {...field} label="Koordinat" name={[field.name, 'latlng']} rules={[{ required: true, message: 'required' }]}>
                                            <Input placeholder="41.008240,28.978359" />
                                        </Form.Item>
                                        <Form.Item {...field} label="Paynet Code" name={[field.name, 'paynetAgentId']} rules={[{ required: true, message: 'required' }]}>
                                            <Input placeholder="3XXXXX" />
                                        </Form.Item>
                                        <Form.Item {...field} label="Addres" name={[field.name, 'address']} rules={[{ required: true, message: 'required' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Space>
                                    <Space align="baseline">
                                        <Form.Item {...field} label="Sorumlu Adı" name={[field.name, 'dealerName']} rules={[{ required: true, message: 'required' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item {...field} label="Sorumlu Email" name={[field.name, 'emailAddress']} rules={[{ required: true, message: 'required' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item {...field} label="Sorumlu Telefon" name={[field.name, 'msisdn']} rules={[{ required: true, message: 'required' }]}>
                                            <Input placeholder="905XXXXXXXXX" />
                                        </Form.Item>
                                    </Space>
                                </div>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add New Row
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
