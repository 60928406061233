import { OrderCloudObj, PlacesCloudObj, rio } from "../../utils/RBS"
import { OrderSearchBody } from "./type"

export const getOrder = async (orderId: string) => {
  const order = await rio.getCloudObject({
    classId: 'Order',
    instanceId: orderId,
    useLocal: true,
  });

  const response: any = await order.call({ method: 'getOrder', httpMethod: 'get' })

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const listOrders = async (body: OrderSearchBody) => {
  const response: any = await OrderCloudObj.call({ method: 'search', body})

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getCities = async () => {
  const response: any = await PlacesCloudObj.call({ method: 'getPlaces', httpMethod: 'get' })


  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const getDistricts = async (city: string) => {
  const response: any = await PlacesCloudObj.call({ method: 'getPlaces', queryStringParams: { city }, httpMethod: 'get' })


  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const exportOrders = async (body: OrderSearchBody) => {
  const response: any = await OrderCloudObj.call({ method: 'exportOrders', body})

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}

export const cancelOrder = async (orderId: string) => {
  const order = await rio.getCloudObject({
    classId: 'Order',
    instanceId: orderId,
    useLocal: true,
  });

  const response: any = await order.call({ method: 'cancelOrder'})

  if (response.status.toString().includes('2')) {
    return { status: true, data: response.data }
  } else {
    return { status: false }
  }
}