import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BarChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Card, Popconfirm } from 'antd'

const { Meta } = Card

export default function ContenetManagerCard({ item, handleConfirm, setAddContentModalVisibility, setSelectedItem, setSurveyResultItem }: any) {
    const navigate = useNavigate()
    const location = useLocation()

    const isSurvey = location.pathname.split('/')[2] === 'surveys'

    return (
        <Card
            hoverable
            onClick={() => {
                navigate(`${item.id}`)
            }}
            style={{ borderRadius: 5, width: 300, cursor: 'pointer' }}
            actions={[
                <EditOutlined
                    key="edit"
                    onClick={e => {
                        e.stopPropagation()
                        setAddContentModalVisibility(true)
                        setSelectedItem(item)
                    }}
                />,
                isSurvey ? (
                    <BarChartOutlined
                        key="edit"
                        onClick={e => {
                            e.stopPropagation()
                            setSurveyResultItem(item)
                        }}
                    />
                ) : undefined,
                <div onClick={e => e.stopPropagation()}>
                    <Popconfirm
                        title="Are you sure to delete this content?"
                        onConfirm={() => {
                            handleConfirm(item)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                </div>,
            ]}
        >
            <Meta
                style={{ height: 75 }}
                title={item.title || item.question}
                description={item?.description?.length > 50 ? item?.description?.substring(0, 50) + '...' : item?.description}
            />
        </Card>
    )
}
